/* On diminue la taille par défaut de la fonte. */
$font-size-base: 0.9rem;

// Required
@import '../../../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../../node_modules/bootstrap/scss/variables-dark'; 
@import '../../../../../../node_modules/bootstrap/scss/maps';
// Optional
@import '../../../../../../node_modules/bootstrap/scss/root';
@import '../../../../../../node_modules/bootstrap/scss/reboot';
@import '../../../../../../node_modules/bootstrap/scss/type';


// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import '../../../../../../node_modules/bootstrap/scss/grid';
@import '../../../../../../node_modules/bootstrap/scss/tables';
@import '../../../../../../node_modules/bootstrap/scss/containers';
@import '../../../../../../node_modules/bootstrap/scss/_forms';
@import '../../../../../../node_modules/bootstrap/scss/buttons';
@import '../../../../../../node_modules/bootstrap/scss/transitions';
@import '../../../../../../node_modules/bootstrap/scss/dropdown';
@import '../../../../../../node_modules/bootstrap/scss/button-group';
//@import '../../../../../../node_modules/bootstrap/scss/input-group';
//@import '../../../../../../node_modules/bootstrap/scss/custom-forms';
@import '../../../../../../node_modules/bootstrap/scss/nav';
@import '../../../../../../node_modules/bootstrap/scss/navbar';
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/jumbotron";
@import '../../../../../../node_modules/bootstrap/scss/alert';
@import '../../../../../../node_modules/bootstrap/scss/progress';
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
@import '../../../../../../node_modules/bootstrap/scss/close';
@import '../../../../../../node_modules/bootstrap/scss/modal';
@import '../../../../../../node_modules/bootstrap/scss/tooltip';
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
@import '../../../../../../node_modules/bootstrap/scss/utilities';
// @import "node_modules/bootstrap/scss/print";

// Surcharge les styles bootstrap qui diffèrent avec Bootstrap 3
.form-check-label {
    font-weight: normal;
}

// Reprise des styles Bootstrap 3 dont on a besoin
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well-sm {
    padding: 9px;
    border-radius: 3px;
}

.nav-justified .disabled a {
    cursor: not-allowed !important;
}