@import './_main-sy-variables';


/************************************************************************************
	DASHBOARD - Accueil et Tableaux de bord
************************************************************************************/

.dashboard-container {
    //margin-top:30px;
    //padding: 30px;
    padding: 60px 40px 40px 40px;
}

@media (max-width: 1366px) {
	.dashboard-container  [class*=col-]{
		padding-right: 5px;
		padding-left: 5px;
	}
}

/*******************************
*** Blocs ***/

/** Liens des blocs **/

.link-box:hover .box-content{
	//transition: $sy-btn-primary-transition;
}

.link-box:hover {
	text-decoration: none !important;
}

.link-box{
	cursor: default !important;
}

.box-content-date {
    background-color: $white;
    padding: 20px;
	min-height: 60px;
	border-radius: 8px;
	text-align: center;
	margin-bottom: 25px;
    box-shadow: $sy-shadowed;
    font-size: $sy-font-size-lg;
}

.box-content-date .subtitle {
    font-weight: $sy-font-weight-bolder;
}

/** Conteneur principal **/

.box-content {
    background-color: $white;
    padding: 20px;
	min-height: 250px;
	border-radius: 8px;
	text-align: center;
	margin-bottom: 25px;
    box-shadow: $sy-shadowed;
}

@media (max-width: 1366px) {
	.box-content {
		padding: 20px;
		min-height: 200px;
	}
}

@media (max-width: 991px) {
	.box-content {
		min-height: 180px;
		margin-bottom: 10px;
	}
}

@media (max-width: 767px) {
	.box-content {
		min-height: 120px;
		border-radius: $sy-main-border-radius;
	}
}

.box-content .title {
    text-align: center;
    font-size: $sy-font-size-lg;
    line-height: 1.25;
	font-weight: $sy-font-weight-normal;
	margin:auto;
}

.box-content .subtitle {
    font-weight: $sy-font-weight-bolder;
}

.box-content .number {
    width: 100%;
	font-size: 1.62rem;
    font-weight: $sy-font-weight-thin;
    color: lighten($gray,10%);
	margin-top: 35px;
    text-align: center;
    white-space: nowrap;
}

.box-content .indTempo{
    color: lighten($gray,10%);
    font-size: $sy-font-size-xxs;
	position: absolute;
    bottom: 40px;
    right: 40px;
}

.box-content .more {
	color: $sy-link-color !important;
    font-size: $sy-font-size-sm;
    font-weight: $sy-font-weight-bolder;
	//position: absolute;
    //bottom: 40px;
    //right: 40px;
}

.box-content .more:hover {
	color: $sy-link-color-hover !important;
}

.box-content .subindicator {
    font-size: $sy-font-size-nm;
	margin-top: 12px;
    color: $gray;
}

.box-content .positif {
    color: $success !important;
}

.box-content .negatif {
    color: $danger !important;
}

.box-content .subindicator span {
    font-size: 1rem;
    font-weight: $sy-font-weight-bolder;
    background-color: $white;
    border-radius: $sy-main-border-radius;
    padding: 5px 10px;
}

@media (max-width: 1366px) {
	.box-content .title {
		font-size: 1rem;
		line-height: 1.2;
	}
	.box-content .number {
		font-size: 2.4rem;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

@media (max-width: 991px) {
	.box-content .number img {
		width: 10%;
	}
	.box-content .number {
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.box-content .number img {
		width: 15%;
	}
}

@media (max-width: 767px) {
	.box-content .title {
		font-size: 1rem;
		line-height: 0.75;
	}
	.box-content .subtitle {
		display: inline;
	}
	.box-content .number {
		font-size: 38px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.box-content .subindicator {
		display: none;
	}
}

/*******************************
*** Box-content Default ***/

.box-content-default {
    background-color: $white;
}

.box-content-default .title {
	color: $gray;
}

/*******************************
*** Box-content Action ***/

.box-content-action {
    background-color: $purple;
}

.box-content-action :hover {
    background-color: darken($purple, 5%);
}

.box-content-action  .title {
	color: $white;
}

/*******************************
*** Box-content Shortcut ***/

.box-content-shortcut {
    background-color: $primary;
	color: $white;
	padding: 0;
	height: 250px;
}

.box-content-shortcut:hover {
	background-color: $secondary;
}

.box-content-shortcut .title {
	font-weight: $sy-font-weight-bold;
	color: $white;
}

@media (max-width: 991px) {
	.box-content-shortcut {
		height: 100px;
	}
}

.box-content-shortcut .box-bg {
	height: 100%;
	background-size: 100%;
    background-clip: border-box;
	background-position: center;
	border-radius: 8px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.box-content-shortcut:hover .box-bg {
	background-size: 105%;
}

/* Indicateur */
.box-content .indic:before {
    content: '';
    position: absolute;
    display: block;
    background: $danger;
    width: 110px;
    height: 55px;
    top: -10px;
    left: 0;
    z-index: -1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 1px solid $danger;
}

.box-content .indic {
    opacity: 1;
    filter: alpha(opacity=100);
    display: block;
    position: absolute;
    width: 55px;
    height: 55px;
    top: 0;
	right: 15px;
    padding-left: 19px;
    padding-top: 6px;
    z-index: 2;
    font-weight: $sy-font-weight-bold;
	overflow: hidden;
	border-top-right-radius: 8px;
}

@media (max-width: 1366px) {
	.box-content .indic {
		right: 5px;
	}
}

/***********************************
*** Grille des Tableaux de bord ***/
/* Version provisoire */

.dashboard-grid h2 {
	color: $sy-body-color;
	font-size: $sy-font-size-xxl;
	font-weight: $sy-font-weight-normal;
	margin: 0 0 20px;
	width: 100%;
	position: relative;
}

.dashboard-grid h2 a {
	position: absolute;
	top: 0;
	right: 0;
	font-size: $sy-font-size-xs;
	font-weight: $sy-font-weight-bold;
	line-height: 25px;
	letter-spacing: 0;
}

/*******************************
*** Barre de notifications ***/
/* Version provisoire */

.dashboard-notifs {
	text-align: left;
    padding: 1rem;
}

.dashboard-notifs h2 {
	font-size: 22px;
	margin: 0 0 20px;
	width: 100%;
	position: relative;
	font-weight: $sy-font-weight-normal;
}

.dashboard-notifs .form-control {
	background: $light;
	margin-bottom: 25px;
}

@media (max-width:1366px) and (min-width:1200px) {
	.dashboard-notifs {
		margin: 0 0 0 20px;
	}
}

.video-player-help {
	width: 100%!important;
 }

.showVideo {
	list-style-type: none;
	outline: none;
}

.align-right {
	text-align: right;
}
