/********************************************/
/* ICON										*/
/********************************************/

.filter-content > ul > li > span:after,
.filter-title:after,
[class*='icon-myfont'] {
    font-family: 'myFont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 32px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.filter-content > ul > li > span:after,
.filter-title:after,
.icon-myfont-arrowdown:before {
    content: '\e600';
}
.icon-myfont-arrowleft:before {
    content: '\e601';
}
.icon-myfont-reinit:before {
    content: '\e602';
}
.icon-myfont-arrowright:before {
    content: '\e603';
}
.icon-myfont-arrowup:before {
    content: '\e604';
}
.icon-myfont-comm:before {
    content: '\e605';
}
.icon-myfont-crayon:before {
    content: '\e606';
}
.icon-myfont-dl:before {
    content: '\e607';
}
.icon-myfont-doc3:before {
    content: '\e608';
}
.icon-myfont-doc2:before {
    content: '\e609';
}
.icon-myfont-doc1:before {
    content: '\e610';
}
.icon-myfont-info:before {
    content: '\e611';
}
.icon-myfont-cle:before {
    content: '\e612';
}
.icon-myfont-trombone:before {
    content: '\e613';
}
.icon-myfont-loupe:before {
    content: '\e614';
}
.icon-myfont-menu:before {
    content: '\e615';
}
.icon-myfont-notif:before {
    content: '\e616';
}
.icon-myfont-deco:before {
    content: '\e617';
}
.icon-myfont-compte:before {
    content: '\e618';
}
.icon-myfont-rond:before {
    content: '\e619';
}
.icon-myfont-exit:before {
    content: '\e620';
}
