.ag-theme-balham .ag-row.row-orange{
    background-color: #ff9317 !important;
    color: #FFFFFF;
	--ag-row-hover-color: #e6810d !important;
    &.ag-row-hover {
         background-color: #e6810d !important;
         color: #FFFFFF;
    }
}



.ag-theme-rapp-manual {
	.ag-details-row {
		padding: 0px;
		
		.ag-root {
		    border: none;
		    border-radius: 0px;
		
		    .ag-header {
		        border: none !important;
		        height: 0px !important;
		        min-height: 0px !important;
		    }
		}
	}

	.ag-header-group-parent {
		border-width: 0px !important;
	}
}

.ag-theme-balham {
	.ag-cell-wrapper .ag-selection-checkbox {
		margin: auto!important;
	}
}

.ag-theme-balham .ag-root {
	border: 1px solid #bdc3c7;
    box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.ag-theme-balham .ag-root-wrapper {
	border: none;
}

.ag-cell.ag-cell-first-right-pinned.ag-cell-focus {
    border-left: solid 1px !important;
    border-left-color: var(--ag-border-color, #bdc3c7) !important;
}

.ag-cell.ag-cell-last-left-pinned.ag-cell-focus {
	border-right: solid 1px !important;
    border-right-color: var(--ag-border-color, #bdc3c7) !important;
}

.ag-pinned-right-header, .ag-pinned-left-header {
	border: none !important;
}
