.row.no-gutters > [class^='col-'],
.row.no-gutters > [class*=' col-'] {
	padding-right: 0;
	padding-left: 0;
}

/************************************************************************************
	BODY
************************************************************************************/

html,
body {
	height: 100%;
}

body {
	padding: 0;
	background: $sy-body-bg no-repeat center fixed;
	font-family: $sy-body-font-family;
	color: $sy-body-color;
	background-size: cover;
	display: flex;
	flex-direction: column;
}

body.app {
	padding: 0;
	background-color: $sy-body-bg;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

body.no-app {
	padding-top: 80px;
}

body.no-bg {
	background-color: $sy-body-bg !important;
}

.overflow-visible-imp {
	overflow: visible !important;
}

.nopadding {
	padding: 0;
}

::selection {
	background: darken($primary, 15%);
	color: $white;
}

b,
strong {
	font-weight: $sy-font-weight-bolder;
}

/************************************************************************************
	HEADINGS
************************************************************************************/

h1,
h2 {
	font-size: $sy-h2-font-size;
	font-weight: $sy-font-weight-normal;
	letter-spacing: -1px;
	color: $sy-h2-color;
	margin: 0 0 20px;
}

h1.border,
h2.border {
	padding-bottom: 15px !important;
	border: 0 !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

h2.border span.pull-right {
	font-size: $sy-h3-font-size;
	font-weight: $sy-font-weight-bold;
	color: $sy-h2-color;
}

h3 {
	font-size: $sy-h3-font-size;
	line-height: 20px;
	font-weight: $sy-font-weight-bold;
	color: $sy-h3-color;
}

h3.title-border {
	padding: 10px !important;
	background: $sy-h3-bg;
	border-radius: $sy-main-border-radius;
	width: 100%;
	margin: 15px 0 12px 0;
}

@media (max-width: 767px) {
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 16px;
	}
}

/************************************************************************************
	LINKS
************************************************************************************/

a,
button,
.btn {
	transition: $sy-link-transition;
	outline: none !important;
	cursor: pointer;
}

a,
[class*='careweb'] a:not([href]),
.careweb-link {
	color: $sy-link-color;
	transition: $sy-link-transition;
	font-weight: $sy-link-font-weight;
}

a:hover,
[class*='careweb'] a:not([href]):hover,
.careweb-link:hover {
	color: $sy-link-color-hover;
	text-decoration: $sy-link-hover-decoration;
	cursor: pointer;
}

.a-link {
	color: $sy-link-color !important;
}

.a-link:hover {
	color: $sy-link-color-hover !important;
	text-decoration: $sy-link-hover-decoration !important;
	cursor: pointer;
}

label a {
	font-weight: $sy-link-font-weight;
}

/************************************************************************************
	FORMS
************************************************************************************/
/* textarea, */
.form-control,
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='date'],
input[type='number'],
input[type='password'] {
	background: $sy-form-bg;
	width: 100%;
	height: $sy-form-height;
	padding: 0 10px;
	font-weight: $sy-font-weight-normal;
	border-radius: $sy-main-border-radius;
	cursor: text;
	border: $sy-form-text-border;
	border-bottom: $sy-form-text-border-bottom;
	box-shadow: $sy-form-box-shadow;
	display: block;
	transition: $sy-form-transition;
}

textarea {
	min-height: $sy-form-height;
	padding-top: 7px !important;
}

/** Focus **/
.form-control:focus,
textarea:focus,
input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='date']:focus,
input[type='number']:focus,
input[type='password']:focus {
	border: $sy-form-border-focus;
	box-shadow: $sy-form-box-shadow-focus;
	outline: 0;
}

/** Disabled **/
.form-control[disabled],
.form-control[disabled]:hover,
textarea[disabled],
textarea[disabled]:hover,
input[type='text'][disabled],
input[type='text'][disabled]:hover,
input[type='email'][disabled],
input[type='email'][disabled]:hover,
input[type='tel'][disabled],
input[type='tel'][disabled]:hover,
input[type='date'][disabled],
input[type='date'][disabled]:hover,
input[type='number'][disabled],
input[type='number'][disabled]:hover,
input[type='password'][disabled],
input[type='password'][disabled]:hover {
	cursor: $sy-form-cursor-disabled;
	box-shadow: none !important;
}

textarea {
	width: 100%;
	border: none;
	padding: 0 10px;
}

select,
.custom-select {
	color: $sy-body-color !important;
	font-weight: 600;
	border-width: $sy-btn-default-border-width;
	border-style: $sy-btn-default-border-style;
	border-color: $sy-btn-default-border-color;
	box-shadow: $sy-dropdown-box-shadow !important;
	cursor: pointer;
}

select:hover,
.custom-select:hover {
	background: $sy-btn-default-bg-hover;
	box-shadow: $sy-dropdown-box-shadow !important;
	transition: $sy-form-transition;
}

/* custom-select avec un popin cf. create-update-sup-entity.html */
.custom-select.popin-select {
	background-position: 89% 57%;
}

select:focus,
.custom-select:focus {
	border: $sy-form-border-focus !important;
	box-shadow: $sy-form-box-shadow-focus !important;
	transition: $sy-form-transition;
}

/* Look n feel des champs disabled */
input.disabled,
input:disabled:not([role='combobox']),
input[disabled]:not([role='combobox']),
input[readonly],
textarea.disabled,
textarea:disabled,
textarea[disabled],
textarea[readonly],
select.disabled,
select:disabled,
select[disabled],
select[readonly],
.ng-select .ng-select-container.disabled,
.ng-select .ng-select-container:disabled,
.ng-select .ng-select-container[disabled],
.ng-select .ng-select-container[readonly],
.ng-select.disabled .ng-select-container,
.ng-select:disabled .ng-select-container,
.ng-select[disabled] .ng-select-container,
.ng-select[readonly] .ng-select-container,
.ng-select.disabled,
.ng-select:disabled,
.ng-select[disabled],
.ng-select[readonly],
.ng-select.ng-select-disabled .ng-select-container {
	background: $sy-form-bg-disabled !important;
	border: $sy-form-border-disabled !important;
	color: $sy-body-color !important;
	box-shadow: none !important;
	cursor: $sy-form-cursor-disabled !important;
}

/* Champs invalid */

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: $danger;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	border-color: $danger !important;
	box-shadow: $sy-form-box-shadow-focus;
}

/************************************************************************************
	BUTTONS
************************************************************************************/

.btn {
	font-size: $sy-btn-font-size;
	color: $sy-btn-color;
	font-weight: $sy-btn-font-weight;
	line-height: 40px;
	border: none;
	padding: 1px 20px;
	text-align: center;
	-webkit-border-radius: $sy-main-border-radius;
	border-radius: $sy-main-border-radius;
	overflow: hidden;
	text-overflow: ellipsis;
	box-shadow: $sy-btn-box-shadow;
	transition: $sy-link-transition;
}

.btn:active,
.btn.active {
	background-image: none;
	outline: 0;
	box-shadow: $sy-btn-box-shadow;
}

.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: none;
}

/** Disabled **/
.btn[disabled],
.btn[disabled]:hover,
button[disabled],
button[disabled]:hover {
	cursor: $sy-form-cursor-disabled;
	box-shadow: none !important;
	opacity: $sy-btn-opacity-disabled !important;
}

/** Small **/
.btn.small {
	padding: 7px 12px;
	font-weight: $sy-font-weight-bold;
	line-height: 23px;
	border-radius: $sy-main-border-radius;
	-webkit-border-radius: $sy-main-border-radius;
	height: $sy-form-height;
}

.btn.small i {
	font-size: $sy-font-size-nm;
}

/* Primary */
.btn.btn-primary,
.btn.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn.blue {
	color: $sy-btn-primary-color;
	background: $sy-btn-primary-bg;
	border: $sy-btn-primary-border;
}

.btn.btn-primary:hover,
.btn.blue:hover {
	background: $sy-btn-primary-bg-hover !important;
	box-shadow: $sy-btn-primary-box-shadow-hover !important;
	transition: $sy-btn-primary-transition;
}

.btn-primary[disabled],
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary[disabled]:hover,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover,
.btn.blue[disabled],
.btn.blue.disabled,
.btn.blue:disabled,
.btn.blue[disabled]:hover,
.btn.blue.disabled:hover,
.btn.blue:disabled:hover {
	background: $sy-btn-primary-bg !important;
	box-shadow: none !important;
}

.btn-primary[disabled]:hover i,
.btn-primary.disabled:hover i,
.btn-primary:disabled:hover i,
.btn.blue[disabled]:hover i,
.btn.blue.disabled:hover i,
.btn.blue:disabled:hover i {
	color: $sy-btn-primary-color !important;
}

/* Secondary */
.btn.btn-secondary,
.btn.btn-secondary:active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn.secondary,
.btn.grey {
	color: $sy-btn-secondary-color;
	background: $sy-btn-secondary-bg;
	border: $sy-btn-secondary-border;
	box-shadow: $sy-btn-box-shadow !important;
}

.btn.btn-secondary:hover,
.btn.secondary:hover,
.btn.grey:hover {
	background: $sy-btn-secondary-bg-hover;
}

.btn-secondary[disabled],
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary[disabled]:hover,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover,
.btn.grey[disabled],
.btn.grey.disabled,
.btn.grey:disabled,
.btn.grey[disabled]:hover,
.btn.grey.disabled:hover,
.btn.grey:disabled:hover {
	background: $sy-btn-secondary-bg !important;
	box-shadow: none !important;
}

.btn-secondary[disabled]:hover i,
.btn-secondary.disabled:hover i,
.btn-secondary:disabled:hover i,
.btn.grey[disabled]:hover i,
.btn.grey.disabled:hover i,
.btn.grey:disabled:hover i {
	color: $sy-btn-secondary-color !important;
}

/* Default */
.btn.btn-default,
.btn.btn-default:active,
.btn.white {
	color: $sy-btn-default-color;
	background: $sy-btn-default-bg;
	border-width: $sy-btn-default-border-width;
	border-style: $sy-btn-default-border-style;
	border-color: $sy-btn-default-border-color;
	font-weight: $sy-font-weight-normal;
}

.btn.btn-default:hover,
.btn.white:hover {
	background: $sy-btn-default-bg-hover;
	color: $black;
	box-shadow: $sy-btn-box-shadow;
}

.btn.btn-default:focus,
.btn.white:focus {
	background: $sy-btn-default-bg;
	color: $black;
	box-shadow: $sy-btn-box-shadow;
}

.btn.btn-default[disabled],
.btn.btn-default.disabled,
.btn.btn-default:disabled {
	border: 1px solid $sy-btn-default-bg;
}

.btn.btn-default[disabled]:hover,
.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover {
	color: $sy-btn-default-color;
	background: $sy-btn-default-bg !important;
	box-shadow: none !important;
}

/* Boutons de la toolbar
 * Boutons contenant juste une icone
 * Exemple : Exporter les resultats en pdf dans la Toolbar
 */
.btn-action {
	background: $sy-btn-default-bg;
	color: $sy-btn-default-color !important;
	/* border-width: $sy-btn-default-border-width;
	border-style: $sy-btn-default-border-style;
	border-color: $sy-btn-default-border-color; */
	border: 1px solid transparent;
	font-size: 14px;
	padding: 0 12px;
}

.btn-action:hover {
	background: $sy-btn-default-bg-hover;
}

.btn-action:hover,
.btn-action:hover a,
.btn-action a:hover {
	text-decoration: none !important;
}

.btn-action:focus {
	background: $sy-btn-default-bg !important;
	color: $black;
	box-shadow: $sy-btn-box-shadow;
}

.btn-action i {
	color: $sy-main-action-icon-color;
}

.btn-action:hover i {
	color: $sy-main-action-icon-color-hover;
}

.btn-action[disabled],
.btn-action.disabled,
.btn-action:disabled,
.btn-action[disabled]:hover,
.btn-action.disabled:hover,
.btn-action:disabled:hover {
	cursor: $sy-form-cursor-disabled;
	opacity: $sy-btn-opacity-disabled;
}

.btn-action[disabled]:hover i,
.btn-action.disabled:hover i,
.btn-action:disabled:hover i {
	color: $sy-main-action-icon-color;
}

/****/
.btn.full {
	width: 100%;
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.grid-bottom span.btn.active {
	background: $sy-btn-primary-bg;
	color: $sy-btn-color;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
	z-index: 2;
	margin-left: -1px;
	border-bottom-left-radius: 0 !important;
	border-top-left-radius: 0 !important;
	box-shadow: none;
}

/* Pour caler exactement les btn.small au box-shadow des input */
.input-group .btn.small {
	box-shadow: none !important;
	height: 37px;
	margin-top: 1px;
}

/************************************************************************************
	LISTES
************************************************************************************/

.list-primary {
	list-style: none;
	padding: 0;
}

.list-primary li {
	padding-left: 20px;
	margin-bottom: 4px;
}

.list-primary li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	font-size: 12px;
	color: $secondary;
	display: inline-block;
	margin-left: -20px; /* idem li */
	width: 20px; /* idem li */
}

/************************************************************************************
	.CONTAINER
************************************************************************************/

.container {
	opacity: 1;
	filter: alpha(opacity=100);
}

/* General */
.no-app .container {
	z-index: 2;
	position: relative;
}

.app .container {
	display: block !important;
	width: 100% !important;
	background: none !important;
	padding: 0;
	overflow: hidden;
	clear: both;
}

[class*='careweb-container'] {
	margin: 0 auto;
	position: relative;
}

.app [class*='careweb-container'] {
	padding-top: 30px;
	z-index: 10;
}

/* Small  */
.small-careweb-container {
	width: 430px;
	padding: inherit;
}

/* Medium */
.med-careweb-container {
	width: 80%;
	padding: 0;
	margin-bottom: 20px;
	margin-top: 110px;
}

.medium-careweb-container {
	width: 50%;
	padding: 0;
	margin-bottom: 20px;
	margin-top: 110px;
}

/* Large */
.large-careweb-container {
	width: 950px;
}

/* X-Large */
.x-large-careweb-container {
	width: 100%;
}

@media (max-width: 767px) {
	.small-careweb-container,
	.med-careweb-container,
	.medium-careweb-container,
	.large-careweb-container {
		width: 100%;
	}
}

@media (max-width: 1199px) {
	.x-large-careweb-container {
		width: 100%;
	}
	.x-large-careweb-container .form-content {
		border-radius: 0;
	}
}

/* Full */
.inner-container {
	padding: 25px 35px 35px;
	background: $sy-body-bg;
	display: block;
	border-radius: $sy-main-border-radius;
	clear: both;
}

/* .inner-container .row {
    margin-bottom: 10px;
} */

.required-container .required-fields {
	font-size: $sy-font-size-xxs;
	color: #333;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
}

/************************************************************************************
	MAIN-CONTENT
************************************************************************************/
/* Conteneur principal des pages de contenu */

.main-content {
	padding: 30px 55px 15px 55px;
}

@media (max-width: 991px) {
	.main-content {
		padding: 15px;
	}
}

/************************************************************************************
	HEADER
************************************************************************************/

/* NO APP */
/* Affichage du header-lang dans l'application */
.no-app header {
	display: inline-block;
	width: 100%;
	margin-top: 50px;
	clear: both;
}

.no-app .logo {
	background: url(../img/logo-caw-square-white.png) no-repeat center top;
	background-size: 186px 130px;
	padding: 45px;
}

.login .logo {
	background: url(../img/logo-caw-square-white.png) center top/190px 69px no-repeat;
	background-position-y: 20px;
	padding: 0;
}

.login .logo header {
	display: inline-block;
	width: 100%;
	margin-top: 50px;
	clear: both;
}

.no-app header .baseline {
	line-height: 50px;
	font-weight: 400;
	color: #41eaff;
	font-size: 13px;
	margin-left: 0;
}

.no-app header nav {
	float: right;
}

.no-app .home-link {
	padding: 15px;
	font-size: 13px;
}

.no-app .hide-no-app {
	display: none;
}

/* APP */
.app .header {
	display: block !important;
	position: relative;
	margin: 0 !important;
	width: 100%;
	z-index: 100;
}
.app .header-top {
	display: block;
	position: fixed;
	z-index: 500;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	background-color: #4a636e;
	background: $sy-main-nav-up-bg url('../img/logo-caw-line-white.png') no-repeat scroll 34px 11px / 201px 63px;
}

/* Element vide situé sous le logo SY permettant de simuler un clic sur ce logo. */
.app .baseline {
	display: block;
	width: 180px;
	height: 100%;
	text-decoration: none;
}

.button-width-180 {
	width: 180px;
}

.button-width-300 {
	width: 300px;
}

.button-width-280 {
	width: 280px;
}

.button-width-270 {
	width: 270px;
}

/************************************************************************************
	FOOTER
************************************************************************************/

/* APP */
.app footer {
	display: block;
	//position: absolute;
	z-index: 3;
	clear: both;
	overflow: hidden;
	height: 65px;
	background-color: $white;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	/* margin-top: 35px; */
}

.app footer ul {
	display: block;
	position: relative;
	padding: 0;
	margin: 0;
}

.app footer ul li {
	display: block;
	position: relative;
	float: left;
	margin-right: 10px;
	line-height: 15px;
	margin-top: 20px;
	font-size: 12px;
	font-weight: 400;
	line-height: inherit;
	color: #666;
}

.app footer ul li a {
	color: #666;
	text-decoration: none;
}

.app footer ul li a:hover {
	color: $sy-body-color;
	text-decoration: $sy-link-hover-decoration;
}

/************************************************************************************
	FORM CONTENT
************************************************************************************/

.form-content {
	padding: 25px 35px 30px;
	background: $sy-form-container-bg;
	display: block;
	border-radius: $sy-main-border-radius;
	clear: both;
}

.form-content-top-pad10 {
	padding: 10px 35px 30px;
	background: $sy-form-container-bg;
	display: block;
	border-radius: $sy-main-border-radius;
	clear: both;
}

.full-careweb-container .form-content {
	padding: 0;
}

.no-bg .form-content {
	background: $sy-form-container-bg;
}

.form-content .form-title {
	padding-bottom: 1rem;
}

.form-content .text-title,
.form-content .img-title {
	text-align: right;
	font-weight: $sy-font-weight-bold;
}

/* Textes */
.form-content .txt {
	padding: 30px;
	font-size: 14px;
	font-weight: 600;
}

.form-content .txt p {
	color: $sy-body-color;
}

.form-content fieldset {
	margin: 0 0 0;
}

.form-content fieldset .row h2 {
	margin: 0 0 10px;
}

.form-content .confirmation fieldset .row h2 {
	margin: 0 0 5px;
}

.form-content .row.action {
	margin-bottom: 0;
	margin-top: 0;
}

.form-content .action-border {
	padding-top: 30px;
	border-top: 1px solid $sy-main-border-color;
}

.form-content .action-no-border {
	padding-top: 30px;
}

@media (max-width: 991px) {
	.form-content {
		padding: 15px;
	}
}

@media (max-width: 767px) {
	.form-content {
		border-radius: 0 !important;
	}
	.form-content .text-title {
		text-align: left;
	}
}

div.ng2-tag-input {
	border: none;
	box-shadow: unset !important;
	background-color: white;
}

input.ng2-tag-input__text-input {
	font-family: unset !important;
}

input.ng2-tag-input__text-input,
input.ng2-tag-input__text-input:focus {
	box-shadow: none;
	border: none;
}

/* Form-content dans les modales */

.modal-dialog .form-content {
	background: $sy-modal-bg !important;
	padding: 0 !important;
}

/** Style pour les ngx-dropdown */

.basic-link-dropdown:hover,
.basic-link-dropdown:focus {
	text-decoration: none;
}

.basic-link-dropdown i,
.basic-link-dropdown .fa,
.basic-link-dropdown .icon-myfont-arrowdown {
	color: $sy-main-action-icon-color;
}

.basic-link-dropdown:hover i,
.basic-link-dropdown:hover .fa,
.basic-link-dropdown:hover .icon-myfont-arrowdown {
	color: $sy-main-action-icon-color-hover;
}

/** Fix affichage de l'icone d'info dans un champs. */
.field.tag-input-popin a {
	margin-top: 8px;
}

/** Décale legèrement à gauche l'icone d'info dans le textarea insurance. */
.insurance-popin > .popin {
	margin-right: 9px;
	margin-top: 2px;
}

/** Croix pour fermer les popups. */
.modal-header button.exit {
	border: none;
}
.modal-header button.exit:hover {
	color: #f00;
}

showdown table td {
	border: $sy-form-border-disabled;
	padding: 8px;
	text-align: left;
}

showdown table th {
	visibility: hidden;
}

careweb-modal-discussions showdown > p {
	margin-bottom: 0px;
}

/**
 * Tableau de bord
 * Affichage sur 5 colonnes
 */
.col-lg-15 {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

@media (min-width: 992px) {
	.col-lg-15 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

careweb-counter div {
	font-size: 48px;
	font-weight: $sy-font-weight-thin;
	top: 50%;
	position: absolute;
	left: 0;
}

@media (max-width: 1366px) {
	.tile-item {
		padding: 20px;
		min-height: 200px;
	}
	careweb-dropfile-tile.tile-item {
		padding: 10px;
	}
}

@media (max-width: 991px) {
	.tile-item {
		min-height: 180px;
	}
}

@media (max-width: 1366px) {
	.tile-item .title {
		font-size: $sy-font-size-nm;
		line-height: 20px;
	}
	.tile-item .subindicator {
		font-size: $sy-font-size-sm;
		line-height: 16px;
	}
	careweb-counter div {
		font-size: 38px;
		top: 40%;
	}
	.tile-item a {
		font-weight: $sy-font-weight-bold;
	}
	.tile-item .gauge {
		width: 130px;
		top: 30px;
		margin-left: -65px;
	}
	careweb-dropfile-tile a {
		margin-top: 100px;
	}
	a.dropfile-link {
		font-size: $sy-font-size-sm !important;
		line-height: 18px !important;
	}
}

@media (max-width: 991px) {
	.tile-item a {
		font-size: $sy-font-size-xxs;
	}

	.tile-item .number img {
		width: 8%;
	}
}

@media (max-width: 767px) {
	.tile-item {
		min-height: 120px;
		margin-bottom: 15px;
		border-radius: 4px;
	}
	.tile-item .title {
		font-size: $sy-font-size-nm;
		line-height: 20px;
	}
	.tile-item .subtitle {
		display: inline;
		margin-left: 5px;
	}
	.tile-item .subindicator {
		display: none;
	}
	.tile-item .gauge {
		width: 100px;
		top: -10px;
		margin-left: -50px;
		margin-bottom: 27px;
	}
}

/************************************************************************************
	BACKGROUNDS
************************************************************************************/

/* No Background (for container full) */
.no-bg {
	background-color: $sy-body-bg !important;
}

/* Background image */
.bg {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: darken($primary, 2%) no-repeat center fixed;
	background-size: cover;
	transition: background 200ms ease-out;
}

.bg ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.bg img {
	width: 100%;
}

.bg .overlay {
	position: relative;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 2;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+28,20abc0+100&0+28,1+100 */
	/* FF3.6+ */
	/* Chrome,Safari4+ */
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(28%, rgba(30, 87, 153, 0)),
		color-stop(100%, rgba(32, 171, 192, 1))
	);
	/* Chrome10+,Safari5.1+ */
	// background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 28%, rgba(32, 171, 192, 1) 100%);
	/* Opera 11.10+ */
	/* IE10+ */
	/* W3C */
	// background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 28%, rgba(32, 171, 192, 1) 100%);
}

/** BACKGROUND COLOR **/

.bg-color,
.bg-color .modal-title,
.bg-color .close {
	color: $white !important;
}

.bg-color .close {
	text-shadow: 0 1px 0 $black !important;
}

.bg-white {
	background-color: $white !important;
}

.bg-primary {
	background-color: $primary !important;
}

.bg-secondary {
	background-color: $secondary !important;
}

.bg-default {
	background-color: $white !important;
}

.bg-success {
	background-color: $success !important;
}

.bg-info {
	background-color: $info !important;
}

.bg-warning {
	background-color: $warning !important;
}

.bg-danger {
	background-color: $danger !important;
}

/************************************************************************************
	TEXT COLOR
************************************************************************************/

.text-primary {
	color: $primary !important;
}

.text-secondary {
	color: lighten($sy-body-color, 15%) !important;
}

.text-success {
	color: $success !important;
}

.text-info {
	color: $info !important;
}

.text-warning {
	color: $warning !important;
}

.text-danger {
	color: $danger !important;
}

/************************************************************************************
	BORDERS surcharge couleurs Bootstrap
************************************************************************************/

.border-top {
	border-top: 1px solid $sy-main-border-color !important;
}

.border-top-dashed {
	border-top: 1px dashed $sy-main-border-color !important;
}

.border-bottom {
	border-bottom: 1px solid $sy-main-border-color !important;
}

.border-bottom-dashed {
	border-bottom: 1px dashed $sy-main-border-color !important;
}

.border-right {
	border-right: 1px solid $sy-main-border-color !important;
}

.border-right-dashed {
	border-right: 1px dashed $sy-main-border-color !important;
}

.border-left {
	border-left: 1px solid $sy-main-border-color !important;
}

.border-left-dashed {
	border-left: 1px dashed $sy-main-border-color !important;
}

/************************************************************************************
	BORDER COLOR
************************************************************************************/

.border-primary {
	border-color: $primary !important;
}

.border-secondary {
	border-color: $secondary !important;
}

.border-success {
	border-color: $success !important;
}

.border-info {
	border-color: $info !important;
}

.border-warning {
	border-color: $warning !important;
}

.border-danger {
	border-color: $danger !important;
}

/************************************************************************************
	SHADOWED - Ombre portée sur les conteneurs
************************************************************************************/
/* Permet d'appliquer une ombre au .form-content de manière spécifique (ex : Pages du compte)
 * Ce style d'ombre est directement appliquée aux éléments suivants :
 * .tile-item
 * .ag-theme-balham .ag-root
 * .notifs .container
*/

.shadowed {
	box-shadow: $sy-shadowed;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-clip: border-box;
	border: 0 solid transparent;
	border-radius: 0.25rem;
	background-color: #ffffff;
}

/************************************************************************************
	MARGIN
************************************************************************************/

/* TODO Style à remplacer par m-0 de bootstrap4 */
.no-margin {
	margin: 0;
}

/* TODO Style à remplacer par mb-0 de bootstrap4 */
.no-margin-bt {
	margin-bottom: 0 !important;
}
.auto-margin {
	margin: auto;
}

.auto-margin-left {
	margin-left: auto;
}

.no-side-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.top-margin-5 {
	margin-top: 5px;
}

.margin-10 {
	margin: 10px;
}

.margin-left-10 {
	margin-left: 10px;
}

.hidden-visibility {
	visibility: hidden;
}

.top-margin-minus-10 {
	margin-top: -10px;
}

.top-margin-7 {
	margin-top: 7px;
}

.left-margin-30 {
	margin-left: 30px;
}

.top-margin-30 {
	margin-top: 30px;
}

/************************************************************************************
	MISC
************************************************************************************/

.pointer {
	cursor: pointer;
}

.pointer .fa {
	color: $primary;
	margin-left: 10px;
}

.not-allowed {
	cursor: not-allowed;
}

.cursor-default {
	cursor: default;
}

.relative {
	position: relative;
}

.textAreaNoResize {
	resize: none;
}

.full-height {
	height: 100% !important;
}

.upload-filename-max-width {
	max-width: 540px;
}

.version {
	font-size: 10px;
	color: $sy-body-color;
	/* border-top: 1px solid #ddd; */
}
.version p {
	margin: 0;
	text-align: right;
	color: $sy-main-action-icon-color;
}
.version b {
	font-weight: $sy-font-weight-normal !important;
}

/* BORDERS */

/* RESET */
.app ul {
	list-style-type: none;
}

.app .main {
	margin-top: 90px;
}

.container-fluid {
	padding: 0px;
}

/* UNDERLINE */
.underline {
	text-decoration: underline;
}

.text-align-center {
	text-align: center;
}

.margin-left-10 {
	margin-left: 10px;
}

/****/
dt {
	font-weight: $sy-font-weight-bold;
}

.form-content-detail-grid {
	background-color: $light;
	border-radius: 5px;
	padding: 1em;
}

a, button, .btn {
	text-decoration: none;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none !important;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
	flex-basis: fit-content !important;
}

.col-auto {
	max-width: 100%;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.close {
    float: right;
    font-size: 1.35rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
	border-color: rgba(0, 0, 0, 0.1) !important;
}