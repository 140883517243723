/************************************************************************************
	STATUS @TODO: to filter
************************************************************************************/

.status {
    font-weight: $sy-font-weight-bold;
}

.status.primary,
.status.blue {
    color: $primary;
}

.status.secondary,
.status.grey {
    color: $secondary;
}

.status.success,
.status.green {
    color: $success;
}

.status.info,
.status.blue-oil {
    color: $info;
}

.status.danger,
.status.red {
    color: $danger;
}

.status.warning,
.status.orange {
    color: $warning;
}

.status.black {
    color: $black;
}

/*** PUCE RAPPROCHEMENT BANCAIRE ***/
.bullet-rapp-count {
    background: #27a344;
   border-radius: 1.3em;
  -moz-border-radius: 1.3em;
  -webkit-border-radius: 1em;
  color: #ffffff !important;
  display: inline-block;
  line-height: 2.5em;
  text-align: center;
  width: 2.5em;
  }
  
/** Puces rondes **/

.status.bullet.alone:after,
.bullet-label:before {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: -1px;
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

div.ui-grid-cell input {
    padding: 6px 12px;
    border-radius: $sy-main-border-radius;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
    width: 90%;
    margin: auto;
    margin-top: 5px;
}

.status.bullet.alone:after {
    margin-right: 0;
    margin-left: 5px;
    margin-bottom: 0;
}

.notif-bck.blue,
.status.bullet.blue:after,
.bullet-label.blue:before {
    background-color: $primary;
}

.notif-bck.grey,
.status.bullet.grey:after,
.bullet-label.grey:before {
    background-color: $secondary;
}

.notif-bck.blue-oil,
.status.bullet.blue-oil:after,
.bullet-label.blue-oil:before {
    background-color: $info;
}

.notif-bck.green,
.status.bullet.green:after,
.bullet-label.green:before {
    background-color: $success;
}

.notif-bck.orange,
.status.bullet.orange:after,
.bullet-label.orange:before {
    background-color: $warning;
}

.notif-bck.red,
.status.bullet.red:after,
.bullet-label.red:before {
    background-color: $danger;
}

.notif-bck.black,
.status.bullet.black:after,
.bullet-label.black:before {
    background-color: $black;
}


/************************************************************************************
	SPINNER
************************************************************************************/

ng-http-loader #spinner[_ngcontent-c1] {
    background-color: rgba(0, 0, 0, 0.6);
}

/************************************************************************************
	INPUTS
************************************************************************************/

/* Ajout d'une '*' quand le champ est required */
.form-group.required label:after,
.form-group label span.required:after {
    font-weight: normal;
    font-size: 14px;
    content: '*';
    margin-left: 4px;
    color: $sy-body-color;
    font-weight: $sy-font-weight-bold;
}

label {
    width: 100%;
    color: $sy-body-color;
    font-weight: $sy-font-weight-bold;
    margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
    .form-group {
        margin-bottom: 0.7rem;
    }
}

/************************************************************************************
	MESSAGES D'ERREUR
************************************************************************************/

/*
.invalid-feedback 	--> ex : Modale Création d'un établissement
.error-messages	 	--> ex : Page "Facture en cours de saisie"
.error				--> ex : Page "Valider ma facture"
*/

.invalid-feedback,
.error-messages.ng-active,
div.error,
label.error,
div.error-field-input {
    color: $danger !important;
    font-size: 80% !important;
    -webkit-transition: $sy-link-transition;
    transition: $sy-link-transition;
}

.invalid-feedback {
    margin-top: -10px;
    margin-bottom: 15px;
    color: $danger;
    display: block;
}

.form-group.field-error.required label:after {
    color: $danger;
}

.field-error label {
    color: $danger;
    -webkit-transition: $sy-link-transition;
    transition: $sy-link-transition;
}

.checkbox label.error {
    position: inherit;
}

.field-error input,
.field-error textarea,
.field-error .dropdown-toggle {
    border: 1px solid $danger !important;
    display: block;
}


/************************************************************************************
	COMPOSANTS
************************************************************************************/

.warning-sign {
    color: orange;
    position: absolute;
    top: 3px;
    right: -5px;
}

.position-relative {
    position: relative;
}


li.del:hover div.notif-item.read > a:before {
    display: none;
}

md-notif-item > div.read :first-child {
    cursor: default;
}

.notif.red:before {
    color: $danger;
}

.notif.blue:before {
    color: $primary;
}

.notif.green:before {
    color: $success;
}

.notif.blue-oil:before {
    color: darken($primary, 5%);
}

.notif.fushia:before {
    color: #db0073;
}

@media (max-width: 767px) {
    .notifs-container {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .notifs {
        max-width: 100%;
    }
}

/* B4 .badge */
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    background-color: $primary;
    color: $sy-badge-color;
}

.badge-secondary {
    background-color: $secondary;
    color: $sy-badge-color;
}

.badge-default {
    background-color: $sy-badge-default-bg !important;
    color: $sy-badge-default-color;
    border: $sy-badge-default-border;
}

.badge-success {
    background-color: $success !important;
    color: $sy-badge-color;
}

.badge-info {
    background-color: $info !important;
    color: $sy-badge-color;
}

.badge-warning {
    background-color: $warning !important;
    color: $sy-badge-color;
}

.badge-danger {
    background-color: $danger !important;
    color: $sy-badge-color;
}

/************************************************************************************
	BELOW FIELD
************************************************************************************/

.below-field,
a.below-field {
    color: $sy-body-color;
    font-weight: 600;
    /* text-decoration: $sy-link-hover-decoration; */
    font-size: 12px;
    line-height: 20px;
    display: block;
    margin-top: 3px;
    -webkit-transition: $sy-link-transition;
    transition: $sy-link-transition;
}
.below-field:hover,
a.below-field:hover {
    color: darken($primary, 2%);
}
/** Accept field */

.accept-field {
    padding: 20px;
}

.accept-field.no-bg {
    padding: 0;
    background-color: inherit !important;
}

.accept-field .checkbox,
.accept-field .radio {
    margin: 0;
}

.accept-field label {
    font-size: $sy-font-size-xs;
    font-weight: $sy-font-weight-bold !important;
    padding-left: 10px;
    width: auto;
    cursor: default;
}

.accept-field.no-bg label {
    color: $sy-body-color;
}

.accept-field a {
    float: right;
    text-decoration: $sy-link-hover-decoration;
    color: darken($primary, 2%);
    font-size: $sy-font-size-xs;
    font-weight: $sy-font-weight-bold;
    -webkit-transition: $sy-link-transition;
    transition: $sy-link-transition;
}

.accept-field a:hover {
    color: $sy-body-color;
}

.accept-field input[type='checkbox'] {
    cursor: pointer;
}

/* Les boutons avec une icone font-awesome réagissant au survol de la souris */
label.btn .fa:hover {
    color: $black;
}

/************************************************************************************
	AFFICHAGE DE L'ICONE AIDE
************************************************************************************/

.field {
    position: relative;
}

.field input {
    position: relative;
    z-index: 1;
}

.field.field-with-info input {
    padding-right: 40px;
}

.popin-btn-container {
    height: 40px;
    position: absolute;
    right: -13px;
    top: -13px;
    width: 40px;
    text-align: center;
    /* line-height: 40px; */
}

h3 .popin-btn-container,
h2 .popin-btn-container,
h1 .popin-btn-container,
label .popin-btn-container,
th .popin-btn-container {
    position: inherit;
    right: inherit;
    top: inherit;
    vertical-align: baseline;
}

.popin-btn-container i {
    color: $primary;
    font-size: $sy-font-size-xxxl;
    /* line-height: 40px; */
}

/* icone info dans un th de tableau */
.ui-grid-like th .popin-btn-container i {
    color: rgba(255, 255, 255, 0.75);
}

.ui-grid-like th .popin-btn-container i:hover {
    color: rgba(255, 255, 255, 1);
}

/* icone info dans un champs */
.field .popin-btn-container i {
    line-height: 40px;
}

.popin-btn-container i:hover {
    color: $sy-btn-primary-bg-hover;
}

h3 .popin-btn-container i,
label .popin-btn-container i,
th .popin-btn-container i {
    vertical-align: sub;
}

.label-popin h3 {
    display: inline-block;
}

.label-popin h3 .info,
.label-popin label .info {
    position: inherit;
    right: inherit;
    top: inherit;
    display: inherit;
    height: inherit;
    vertical-align: middle;
    text-decoration: none;
}

.label-popin .info,
.field .info {
    position: absolute;
    z-index: 2;
    right: 12px;
    top: 10px;
    display: block;
    width: 17px;
    height: 17px;
}

.select-popin .info {
    right: 45px;
    top: 12px;
}

.label-popin .info {
    right: 28px;
}

.label-popin label {
    padding-right: 35px;
    line-height: 35px;
}

/************************************************************************************
	TOASTERS
************************************************************************************/

.toast-container .toast {
    box-shadow: $sy-dropdown-menu-box-shadow;
}

.toast-info {
    background-color: darken($primary, 2%);
}

.toast-warning {
    background-color: $warning;
}

.toast-error {
    background-color: $danger;
}

/************************************************************************************
	SCROLLBAR override
************************************************************************************/

.ngsb-wrap .ngsb-scrollbar .ngsb-track {
    background: rgba(147, 152, 155, 0.8);
}

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos .ngsb-thumb {
    background: rgba(75, 71, 72, 0.8);
}

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos:hover .ngsb-thumb {
    background: rgba(75, 71, 72, 0.8);
    filter: 'alpha(opacity=40)';
    -ms-filter: 'alpha(opacity=40)';
}

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos:active {
    background: rgba(147, 152, 155, 0.6);
    filter: 'alpha(opacity=60)';
    -ms-filter: 'alpha(opacity=60)';
}

/** TABLE */
table th {
    font-weight: $sy-font-weight-bold;
}

table > tbody tr > td.delete-col {
    padding-left: 0;
    line-height: 35px;
    width: 20px;
    font-size: $sy-font-size-nm;
    cursor: pointer;
}

/************************************************************************************
	FORMULAIRE DYNAMIQUE
************************************************************************************/

careweb-section .form-group {
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 1rem;
}

careweb-section input[type='number'] {
    text-align: right;
}

careweb-section textarea {
    resize: none;
}

careweb-section p.input-group {
    margin-bottom: 0;
}

careweb-section label {
    margin-bottom: 2px;
}

div.section-form-row {
    margin-bottom: 0 !important;
    margin-left: -15px !important;
    margin-right: 15px !important;
}

/************************************************************************************
	PDF VIEWER
************************************************************************************/

/** TOOLBAR **/

.pdf-toolbar {
    margin-bottom: 15px;
}

.pdf-toolbar .item-control.text {
    padding-top: 7px;
    font-weight: $sy-font-weight-bold;
}

/** VIEWER COMPONENT **/

.pdf-viewer {
    width: 100%;
    background-color: $dark;
    border: none;
    border-radius: 6px;
}

.pdf-viewer .pdf-container {
    width: 100%;
    overflow: auto;
    padding: 25px 0;
    text-align: center;
    max-height: 1000px;
    color: $white;
}

.pdf-viewer .pdf-container .pdf-document .pdf-canvas {
    -o-box-shadow: 0px 0px 30px 0px #222;
    box-shadow: 0px 0px 30px 0px #222;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#222, Direction=NaN, Strength=30);
    border: 1px solid $black;
}

.pdf-viewer .pdf-container .pdf-document .rotate0 {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.pdf-viewer .pdf-container .pdf-document .rotate90 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.pdf-viewer .pdf-container .pdf-document .rotate180 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.pdf-viewer .pdf-container .pdf-document .rotate270 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
/** Logo société */
.legalentity-logo {
    width: 60px;
    height: 45px;
}

@media (max-width: 767px) {
    careweb-pdf-viewer {
        margin-top: 35px;
    }
}

/************************************************************************************
	TABLEAUX
************************************************************************************/

.table th,
.table td {
    padding: 0.75rem 0.4rem;
}

/************************************************************************************
	TABLEAU DE L'ECRAN : INFO COMPLEMENTAIRE SOCIETE
************************************************************************************/

.table-header {
    width: 100%;
    margin-bottom: 30px;
}

.table th,
.table td,
.table-header tbody tr {
    border-top: 1px solid $sy-main-border-color;
    background: $white;
}

.table-header tbody tr td {
    background-color: $sy-form-bg;
    padding: 6px 0;
}

.table-header thead tr {
    height: 35px;
}

.table-header thead tr th {
    color: $sy-grid-thead-color;
    font-size: $sy-font-size-xs;
    font-weight: $sy-font-weight-bold;
    line-height: 15px;
    background-color: $sy-grid-thead-bg;
    text-align: left;
    padding-left: 1.1rem;
}

.table-header thead tr th:first-child {
    border-radius: $sy-main-border-radius 0 0 0;
}

.table-header thead tr th:last-child {
    border-radius: 0 $sy-main-border-radius 0 0;
}

.table-header div.form-check {
    margin-left: 55px;
}

.table-label-checkbox {
    width: auto;
}

/* .table-header tbody tr td input[type='text'],
.table-header tbody tr td ol button {
    border: $sy-form-border;
} */

.table-header careweb-error-messages div {
    position: relative;
}

.table-header tbody tr td textarea {
    resize: vertical;
}

/**
 * Table ayant le même look qu'une grille ui-grid.
 * Exemple :
 * <table class="ui-grid-like">
 * 		<thead>
 *			<tr>
 *				<th>Titre colonne</th>
 *				...
 *			</tr>
 *		</thead>
 *		<tbody>
 *			...
 *		</tbody>
 * </table>
 */
.ui-grid-like {
    width: 100%;
}

.ui-grid-like thead tr {
    height: 35px;
}

.ui-grid-like th {
    line-height: 15px;
    font-weight: 700;
    font-size: 14px;
    background-color: white;//$sy-grid-thead-bg;
    padding: 15px;
    color: black;
    /* border-right: 1px solid rgb(135, 133, 134); */
    border-bottom: 1px solid rgb(135, 133, 134);
}

.ui-grid-like th:first-child {
    border-radius: 7px 0 0 0;
}

.ui-grid-like th:last-child {
    border-radius: 0 7px 0 0;
    border-right: 0;
}

.ui-grid-like th:first-child:last-child {
    border-radius: 7px 7px 0 0;
    border-right: 0;
}

.ui-grid-like tbody tr {
    border-top: 1px solid $sy-main-border-color-ultra-light;
}

.ui-grid-like tbody tr td {
    padding: 10px 15px;
    background-color: #fff;
}

/************************************************************************************
	TABLEAU DE L'ECRAN UTILISATEUR
************************************************************************************/

.table-user-info {
    margin-bottom: 30px;
}
.table-user-info thead tr th .popin-btn-container {
    line-height: 0;
}
.table-user-info thead tr th .popin-btn-container i {
    line-height: 0;
}
.table-user-info thead tr th a {
    text-decoration: none;
    vertical-align: middle;
}

.input-menu-container .btn {
    border-radius: 0;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    margin-top: -8px;
    box-shadow: none;
}

.input-menu-container .fa-chevron-circle-down {
    color: $sy-main-action-icon-color;
}

.input-item li a,
.input-item li a:not([href]) {
    color: $sy-body-color;
}

/** Dropdown actions dans les tableaux **/
.input-item.dropdown-menu-right {
    right: -35px !important;
    top: -3px;
    left: initial;
    border-radius: $sy-main-border-radius;
    box-shadow: $sy-dropdown-menu-box-shadow;
}

.input-item.dropdown-menu-right a.dropdown-item {
    padding: 10px;
}

.input-item.dropdown-menu-right a.dropdown-item:hover {
    background: $sy-dropdown-menu-hover;
}

.link-ubl-report {
    color: $danger !important;
    text-decoration: $sy-link-hover-decoration;
}

/************************************************************************************
	TITLED SECTION
************************************************************************************/
/* Section possédant un titre
 * Exemple :
 * <section class="titled-section">
 *		<h1>Le titre de la section</h1>
 *		...
 * </section>
 */

.titled-section > h1 {
    color: $sy-h2-color;
}

.titled-section > h1 {
    font-size: $sy-h2-font-size;
    font-weight: 700;
}

.titled-section + .titled-section {
    margin-top: 1em;
}

/* Bloc clé-valeur mettant en valeur la clé.
 * Exemple :
 * <dl class="inline-labeled-value">
 * 		<dt>Une clé</dt>
 * 		<dd>Une valeur</dd>
 * </dl>
 */

dl.inline-labeled-value {
    margin: 0 0 5px 0;
}

dl.inline-labeled-value .badge {
    margin-right: 5px;
}

dl.inline-labeled-value dt,
dl.inline-labeled-value dd {
    display: inline-block;
}

dl.inline-labeled-value dd {
    color: $sy-body-color;
    font-weight: $sy-font-weight-bold;
}

dl.inline-labeled-value dd {
    margin-left: 0.4em;
}

/************************************************************************************
	BARRE D'ACTION
************************************************************************************/
/* Barre d'action en bas des pages
 * Exemple :
 * <div class="button-bar">
 *		<button...>
 * </div>
 */

.button-bar {
    text-align: right;
    //margin-top: 15px;
}

.button-bar .btn {
    margin-left: 7px;
}

@media (max-width: 767px) {
    .button-bar {
        padding-left: 0;
        padding-right: 0;
    }
    .button-bar .btn {
        margin: 0 0 5px 0;
        width: 100%;
    }
}

/************************************************************************************
	Colonne de type numérique
************************************************************************************/

/* .cell-number {
    text-align: right;
} */

span.error-messages {
    color: $danger;
    font-size: 11px;
    margin-left: 5px;
}

/************************************************************************************
	SIDEBAR
************************************************************************************/
/** Surcharge des filtres */

.checkbox-filter .input-group-btn .btn i {
    position: absolute;
    top: 12px;
    right: 13px;
}

.checkbox-filter .input-group-btn .btn i:hover {
    color: #fff;
}

/** Champs + Bouton Calendar */

.input-group .form-control[type='text'][bsdatepicker] {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39);
}

.input-group .form-control[type='text'][bsdatepicker]:focus {
    box-shadow: $sy-form-box-shadow-focus;
}

.checkbox-filter .input-group-btn .btn,
.input-group-btn .btn.btn-calendar,
.input-group-btn .btn.btn-calendar:active {
    height: 36px;
    width: 35px !important;
    background-color: $sy-btn-secondary-bg;
    color: $sy-btn-secondary-color;
    margin-top: 1px;
    box-shadow: none !important;
}

/** Surcharge datepicker ngx-bootstrap */

.bs-datepicker {
    box-shadow: $sy-dropdown-menu-box-shadow;
    background: $light;
    border-radius: $sy-main-border-radius;
}

.bs-datepicker-container {
    padding: 0;
    z-index: 15;
}

.bs-datepicker-body {
    border-radius: 0;
    border: none;
}

.bs-datepicker-head {
    background-color: $sy-main-nav-up-bg;
    border-left-top-radius: $sy-main-border-radius;
    border-right-top-radius: $sy-main-border-radius;
}

.bs-datepicker-head button {
    font-size: $sy-font-size-nm;
    font-weight: $sy-font-weight-bold;
    text-transform: capitalize;
}

/* Icones fleches */
.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
    line-height: 0.3;
}

/* Conteneurs des Jours : Lun Mar Mer Jeu etc. */
.bs-datepicker-body table th,
.bs-datepicker-body table td {
    font-size: $sy-font-size-sm;
    color: lighten($sy-body-color, 25%);
    font-weight: $sy-font-weight-bold;
}

/* Conteneur des Numero de jours */
.bs-datepicker-body table td:not(.week) {
    background-color: $white;
    color: $sy-body-color !important;
    font-weight: $sy-font-weight-bold;
}

.bs-datepicker-body table td:not(.week):hover {
    color: $sy-body-color !important;
}

.bs-datepicker-body table td span {
    font-size: $sy-font-size-sm;
    border-radius: $sy-main-border-radius;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
    color: #b3b2b2;
    font-weight: 400;
}

.bs-datepicker-body .is-other-month {
    color: lighten($sy-body-color, 50%) !important;
    font-weight: $sy-font-weight-normal;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
    background-color: $sy-main-bg-selected;
    color: $sy-body-color;
}

.dropdown-grey-hover:hover {
    background-color: $sy-dropdown-menu-hover;
}

/* Jour selectionné */
.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
    color: $white;
    background: $primary;
}

/************************************************************************************
	CHECKBOX
************************************************************************************/
/** Surcharge awesome-bootstrap-checkbox */

.abc-checkbox {
    padding-bottom: 6px;
}

.abc-checkbox-primary input[type='checkbox']:checked + label::before {
    background-color: $primary;
    border-color: $primary;
}

/************************************************************************************
	ACCORDION - SIDEBAR FILTERS
************************************************************************************/
/** Surcharge pour le composant angular-material-accordion */

.accordion-filter .mat-expansion-panel {
    background-color: lighten($dark, 5%);
}

.accordion-filter .mat-expansion-panel.mat-expanded,
.accordion-filter .mat-expansion-panel-content,
.accordion-filter .mat-expansion-panel:hover {
    background-color: darken($dark, 5%);
    transition: $sy-link-transition;
}

.accordion-filter .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
}

// Fleches des filtres
.accordion-filter .mat-expansion-indicator,
.accordion-filter .mat-expansion-indicator::after,
.accordion-filter .mat-expansion-panel-header-description {
    color: rgba(255, 255, 255, 0.7);
}

.accordion-filter .accordion-badge {
    margin-left: auto;
    font-size: 16px;
    color: $white;
    font-weight: $sy-font-weight-bold;
    background-color: lighten($dark, 18%);
    height: 22px;
    width: 22px;
    border-radius: $sy-main-border-radius;
    text-align: center;
    align-self: center;
}

.accordion-filter .mat-expanded .accordion-badge,
.accordion-filter .mat-expansion-panel:hover .accordion-badge {
    background-color: $sy-btn-primary-bg-hover;
}

.accordion-filter mat-expansion-panel .icon-myfont-exit {
    background-color: $secondary;
    border-left: 1px solid $dark;
}

.accordion-filter mat-expansion-panel {
    border: none;
    margin-top: 1px !important;
}

.accordion-filter mat-panel-title {
    display: block;
    height: 50px;
    padding-left: 0;
    line-height: 50px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    font-family: $sy-body-font-family;
}

.accordion-content label {
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
}

.accordion-filter mat-expansion-panel .filter-label {
    background-color: lighten($dark, 5%);
    color: rgba(255, 255, 255, 0.85);
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: -webkit-fill-available;
}

.accordion-filter mat-expansion-panel careweb-filter-label:first-of-type {
    display: block;
    margin-top: 20px;
}

/** Surcharge les typeahead ngx-boostrap se trouvant dans un accordeon de filtre. **/
.accordion-filter .dropdown-item.active {
    background-color: $sy-dropdown-menu-selected;
    color: $sy-body-color;
}

.accordion-filter .dropdown-item:hover {
    cursor: pointer;
    background-color: $sy-dropdown-menu-hover;
}

/** Surcharge les champs se trouvant dans un accordeon de filtre. **/
.accordion-filter input[type='text'],
.accordion-filter input[type='number'],
.accordion-filter .input-group .form-control[type='text'][bsdatepicker] {
    box-shadow: none;
}

/** Surcharge les button group se trouvant dans un accordeon de filtre. **/
.accordion-filter .checkbox-filter .input-group-btn .btn,
.accordion-filter .input-group-btn .btn.btn-calendar {
    height: 38px;
    margin-top: 0;
}

/************************************************************************************
	TOOLTIPS
************************************************************************************/

.tooltip.show {
    opacity: 1;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-color: $sy-tooltip-border-color !important;
}

.tooltip-inner {
    max-width: 250px;
    padding: 0.35rem 0.65rem;
    color: $sy-tooltip-color;
    background-color: $sy-tooltip-bg;
    border-radius: 0.3rem;
}

/************************************************************************************
	CUSTOM CHECKBOX - Bootstrap component
************************************************************************************/

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary;
}

/************************************************************************************
	NAV TABS
************************************************************************************/
/* Navigation (ex : Page Mon entreprise) */

.nav-tabs .nav-link {
    font-size: $sy-font-size-nm;
    color: lighten($sy-body-color, 38%);
    font-weight: $sy-font-weight-bold;
    border: 1px solid transparent;
    border-bottom: 2px solid $sy-main-border-color;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    transition: $sy-form-transition;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: $sy-main-bg-selected $sy-main-bg-selected $sy-main-bg-selected;
    background: $sy-main-bg-selected;
    color: $sy-body-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: $sy-body-color;
    background-color: $white;
    border-color: $sy-main-border-color-ultra-light $sy-main-border-color-ultra-light transparent;
    border-bottom: 2px solid $primary;
    cursor: default;
}

@media (max-width: 767px) {
    .nav-tabs .nav-item {
        flex-basis: 100%;
    }
    .nav-tabs .nav-link {
        padding: 0.8rem 1rem;
        text-transform: uppercase;
        font-size: $sy-font-size-xs;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

/************************************************************************************
	TABS CONTAINER
************************************************************************************/
/* Conteneurs des formulaires liés à des .nav-tabs (ex : Page Mon entreprise) */

.tabs-container {
    background: $sy-body-bg;
    /* border: 1px solid $sy-main-border-color-ultra-light; */
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 15px 0;
}

.tabs-container h2 {
    font-size: 24px;
    font-weight: 400;
}

@media (max-width: 767px) {
    .tabs-container {
        padding: 0;
    }
}

/************************************************************************************
	LISTES DEROULANTES - .DROPDOWN
************************************************************************************/

/** Button **/
.dropdown-button {
    height: $sy-form-height !important;
    transition: $sy-form-transition;
}

.dropdown-button:focus {
    border: $sy-form-border-focus;
    box-shadow: $sy-form-box-shadow-focus;
    outline: 0;
}

/** Toggle **/
.dropdown-toggle {
    width: 100%;
    position: relative;
    border-radius: 0;
    display: block;
    background: $sy-btn-default-bg;
    box-shadow: none;
    height: 40px;
    border: none;
    font-weight: $sy-font-weight-bold;
    padding-right: 13px;
}

.dropdown-toggle .bs-caret {
    border-left: 1px solid $sy-main-border-color-ultra-light;
    width: 35px;
    display: block;
    position: absolute;
    height: 35px;
    top: 0;
    right: 0;
}

.dropdown-toggle .bs-caret .caret {
    margin-top: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 6px 0 6px;
    border-color: $sy-main-action-icon-color transparent transparent transparent;
}

/** Menu **/
.dropdown-menu {
    padding: 0;
    border-radius: 0px;
    cursor: pointer;
    -webkit-box-shadow: $sy-dropdown-menu-box-shadow;
    box-shadow: $sy-dropdown-menu-box-shadow;
}

.dropdown-menu > li > a {
    font-weight: 600;
    padding: 5px 12px;
    text-decoration: none;
    font-size: 13px;
}

.dropdown-menu > li.active > a {
    background-color: $sy-dropdown-menu-hover;
    background-repeat: repeat-x;
}

.dropdown-menu > li.active > a {
    color: $sy-body-color;
    text-decoration: none;
    background-color: $sy-dropdown-menu-hover;
}

.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-color: $sy-dropdown-menu-hover;
    background-repeat: repeat-x;
    color: $sy-link-color-hover;
}

/************************************************************************************
	LISTES DEROULANTES - @ng-select/ng-select
************************************************************************************/

.ng-input > input {
    padding-left: 0;
}

/* Container */
.ng-select .ng-select-container {
    background: $sy-btn-default-bg;
    color: $sy-btn-default-color !important;
    font-weight: $sy-font-weight-bold;
    border-width: $sy-btn-default-border-width;
    border-style: $sy-btn-default-border-style;
    border-color: $sy-btn-default-border-color;
    box-shadow: $sy-dropdown-box-shadow;
    transition: $sy-form-transition;
    cursor: pointer !important;
    width: 100%;
}

.ng-select.ng-select-single .ng-select-container,
.ng-select.ng-select-multiple .ng-select-container {
    height: $sy-form-height;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0;
}

/* Hover */
.ng-select .ng-select-container:hover {
    background: $sy-btn-default-bg-hover;
    box-shadow: $sy-dropdown-box-shadow;
}

/* Focus */
.ng-select.ng-select-focused .ng-select-container {
    border: $sy-form-border-focus !important;
    box-shadow: $sy-form-box-shadow-focus !important;
}

/* Fields in the container */
.ng-select.ng-select-focused input {
    border: none !important;
    box-shadow: none !important;
}

/* Placeholder */
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: $sy-body-color;
    font-weight: $sy-font-weight-normal;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: auto;
    padding-bottom: 0;
    padding-left: 0;
}

/* Icon */
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid $sy-main-border-color !important;
}

/************************************************************************************
	LISTES DEROULANTES - @ng-dropdown-panel
************************************************************************************/

.ng-dropdown-panel {
    box-shadow: $sy-dropdown-menu-box-shadow;
}

.ng-dropdown-panel .scroll-host {
    text-align: left;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $sy-dropdown-menu-hover;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: $sy-dropdown-menu-selected !important;
}

/************************************************************************************
	MARKDOWN
************************************************************************************/
showdown.markdown ul {
    list-style-type: disc;
}
