
/************************************************************************************
	LOGIN
************************************************************************************/

.loginPageBackground {
    background-image: url("../img/bg/background3.jpg"); 
}

.rapp-link {
    background-image: url("../img/link.png");
    background-repeat: no-repeat;
    height: 380px;
    background-size: cover;
}

.rapp-add {
    background-image: url("../img/add.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
}

.rapp-restaure {
    background-image: url("../img/restaure.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
}



.loiPageBackground {
    background-image: url("../img/bg/background3.jpg"); 
}

/* Lien vers les pages de récupération de mot de passe/identifiant */
.login .form-content fieldset .row.action ul {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

.login .form-content fieldset .row.action ul li {
    color: $sy-body-color;
    font-weight: 400;
    font-size: 13px;
    transition: $sy-link-transition;
}

.login .form-content fieldset .row.action ul li {
    color: darken($primary, 2%);
}

.login .back-link {
    font-weight: 600;
    line-height: 50px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    text-decoration: $sy-link-hover-decoration;
    transition: $sy-link-transition;
}

.login .back-link:hover {
    color: darken($primary, 2%);
}

/** FIX : moche mais pas de meilleur solution pour l'instant */
.login.recover-id .small-careweb-container {
    width: 750px;
}

.login.recover-id .identifiant.no-margin-bt {
    margin-bottom: 15px !important;
}


/************************************************************************************
	DASHBOARDS
************************************************************************************/

.dashboard .main {
    margin-bottom: 30px;
}

.dashboard-container {
    padding: 42px;
}

.dashboard-container .content {
    margin-left: 55px;
    margin-right: 24px;
}

@media (max-width: 1366px) {
    .dashboard-container [class*='col-'] {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media (max-width: 767px) {
	.dashboard-container {
		padding-bottom: 0;
	}
}


/************************************************************************************
	INVOICES
************************************************************************************/

.invoices.detail .inner-container p label {
    display: inline;
}

/* DASHBOARD */
.invoices.dashboard .tiles .tile:first-child {
    margin-left: 0;
}

.invoices.dashboard .container h2 a {
    position: absolute;
    top: 0;
    right: 0;
    color: $primary;
    font-size: 13px;
    line-height: 25px;
    font-weight: 600;
    text-decoration: $sy-link-hover-decoration;
    transition: $sy-link-transition;
    letter-spacing: 0;
}

ul li.tile a.bottom:hover {
    color: $sy-link-color-hover;
}

/* PARTIE GRID */

.invoices.dashboard .grid .row .col-md-12 {
    padding-left: 0;
}
.invoices.dashboard h2 {
    font-size: 22px;
    margin: 0 0 20px 0;
    width: 100%;
    position: relative;
}

.invoices.dashboard h2 a {
    position: absolute;
    top: 0;
    right: 0;
    font-size: $sy-font-size-sm;
    line-height: 25px;
    font-weight: $sy-link-font-weight;
    text-decoration: $sy-link-hover-decoration;
    transition: $sy-link-transition;
    text-decoration: none;
    letter-spacing: 0;
}

.invoices.dashboard h2 a:hover {
    color: $sy-link-color-hover;
    text-decoration: $sy-link-hover-decoration;
}

.invoices.dashboard .container .pushs {
    padding-left: 20px;
}
.large-tiles {
    margin: 35px 0 0;
    padding: 0;
}

/* ==========================================================================
   Construction de barre verticale de filtre
   ========================================================================== */

.filter-container {
    display: block;
    float: left;
    position: relative;
    background-color: $dark;
    margin-top: -6px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    z-index: 2;
}
.filter-container-inner {
    margin-left: -310px;
    width: 310px;
    transition: margin-left 200ms ease-out 0s;
}
.filter-container-inner.show-content {
    margin-left: 0;
}
.filter-container-inner.init-hidden {
    display: none;
}
.filter-top {
    clear: both;
    overflow: hidden;
    background-color: $dark;
    padding: 25px;
}

.filter-top button {
    float: left;
    position: relative;
    text-align: left;
    border: 0 none;
    color: rgba(255, 255, 255, 1);
    /* font-size: 13px; */
    font-weight: 400;
    width: auto;
    height: 40px;
    border-radius: $sy-main-border-radius;
    background-color: $secondary;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
}

.filter-top button span {
    position: absolute;
    right: 0;
    top: 7px;
    line-height: 26px;
    height: 26px;
    width: 36px;
    text-align: center;
    border-left: 1px solid #888787;
}

.filter-top button.search {
    width: auto;
    background: $primary;
    font-weight: 600;
    color: #fff;
    padding: 6px 12px;
    margin-top: 5px;
}

.filter-top button.search:hover {
    background: $sy-btn-primary-bg-hover !important;
}

.filter-top button.search span,
.filter-top button.search i {
    display: none;
}

.filter-top button.reinit {
    margin-left: 0;
    background: $sy-btn-default-bg;
    border-width: $sy-btn-default-border-width;
	border-style: $sy-btn-default-border-style;
	border-color: $sy-btn-default-border-color;
    margin-top: 5px;
}

.filter-top button.reinit:hover {
    background: $sy-btn-default-bg-hover !important;
}

.filter-top button.reinit i,
.filter-top button.reinit span {
    font-size: 16px;
    color: $sy-main-action-icon-color;
}

.filter-top button.reinit:hover i,
.filter-top button.reinit:hover span {
    font-size: 16px;
    color: $sy-main-action-icon-color-hover;
}

.filter-top button.exit {
    color: rgba(255, 255, 255, 0.5);
    float: right;
    position: absolute;
    top: 4px;
    right: -4px;
    background: transparent;
}

.filter-top button.exit:hover {
    color: rgba(255, 255, 255, 1);
    transition: $sy-link-transition;
}

.filter-top button i,
.filter-top button span {
    position: absolute;
    right: 0;
    top: 7px;
    line-height: 26px;
    height: 26px;
    width: 36px;
    text-align: center;
    border: none;
    font-size: 18px;
}

#filter .filter-content > uib-accordion .panel {
    background-color: inherit;
    padding: 0;
    margin: 0 0 1px 0;
    border: inherit;
}

.invoices.list .filter-container .filter-content,
.invoices.list .filter-container .filter-content > ul {
    margin: 0;
    padding: 0;
}

.filter-item,
.invoices.list .filter-container .filter-content > ul > li {
    display: block;
    position: relative;
    clear: both;
    padding: 0;
    margin: 0;
    margin-bottom: 1px;
    background-color: $dark;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.filter-item:hover,
.panel-open .filter-item,
.invoices.list .filter-container .filter-content > ul > li.active,
.invoices.list .filter-container .filter-content > ul > li:hover {
    background-color: $primary;
    color: $sy-link-color-hover;
}

.filter-item:hover,
.invoices.list .filter-container .filter-content > ul > li:hover {
    background-color: $sy-body-color;
}

.panel-open .filter-item,
.invoices.list .filter-container .filter-content > ul > li.active {
    background-color: $sy-body-color;
}

.panel-open .filter-item .filter-title {
    color: rgba(255,255,255,1);
}

.filter-title {
    display: block;
    height: 50px;
    padding-left: 30px;
    line-height: 50px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.filter-title .badge {
    right: 60px;
    top: 15px;
}
.filter-title:after {
    display: block;
    position: absolute;
    right: 30px;
    top: 10px;
}

.invoices.list .conf-col ol button {
    padding: 0 40px 0 12px;
    border: 0 none;
    background: $sy-btn-default-bg;
    color: $sy-main-action-icon-color;
    border-color: $sy-main-border-color;
    font-size: $sy-font-size-xxs;
    font-weight: $sy-font-weight-bold;
    height: 30px;
    line-height: 30px;
    border-radius: 0;
}
/* Permet a la dropdown de passer sous le header lors du scroll */

.invoices.list #columnSelect .dropdown-menu {
    position: relative;
    margin-top: -15px;
    z-index: 10;
}
.invoices.list .conf-col .dropdown-menu > li > a {
    padding: 6px 30px;
}
.invoices.list .conf-col .dropdown-menu > li > a:hover,
.invoices.list .conf-col .dropdown-menu > li > a:focus {
    background-color: #fff;
    background-image: none;
    filter: none;
}
.invoices.list .conf-col .dropdown-menu > li > a label {
    display: block;
    position: relative;
    clear: both;
    color: $sy-main-action-icon-color;
    font-size: $sy-font-size-xxs;
    font-weight: $sy-font-weight-bold;
    line-height: 18px;
}
.invoices.list .conf-col .btn-group.open .dropdown-toggle {
    box-shadow: none;
}

/** Facture à valider */
.invoices.validation .line-error .error {
    position: relative;
}