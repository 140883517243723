@charset "UTF-8";
.row.no-gutters > [class^=col-],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

/************************************************************************************
	BODY
************************************************************************************/
html,
body {
  height: 100%;
}

body {
  padding: 0;
  background: #f1f4f8 no-repeat center fixed;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #333;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

body.app {
  padding: 0;
  background-color: #f1f4f8;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

body.no-app {
  padding-top: 80px;
}

body.no-bg {
  background-color: #f1f4f8 !important;
}

.overflow-visible-imp {
  overflow: visible !important;
}

.nopadding {
  padding: 0;
}

::selection {
  background: #167d8d;
  color: #fff;
}

b,
strong {
  font-weight: 700;
}

/************************************************************************************
	HEADINGS
************************************************************************************/
h1,
h2 {
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: -1px;
  color: #333;
  margin: 0 0 20px;
}

h1.border,
h2.border {
  padding-bottom: 15px !important;
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

h2.border span.pull-right {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333;
}

h3 {
  font-size: 1.125rem;
  line-height: 20px;
  font-weight: 600;
  color: #333;
}

h3.title-border {
  padding: 10px !important;
  background: #cfd9e7;
  border-radius: 4px;
  width: 100%;
  margin: 15px 0 12px 0;
}

@media (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 16px;
  }
}
/************************************************************************************
	LINKS
************************************************************************************/
a,
button,
.btn {
  transition: all 0.3s ease 0s;
  outline: none !important;
  cursor: pointer;
}

a,
[class*=careweb] a:not([href]),
.careweb-link {
  color: #21b8cf;
  transition: all 0.3s ease 0s;
  font-weight: 600;
}

a:hover,
[class*=careweb] a:not([href]):hover,
.careweb-link:hover {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.a-link {
  color: #21b8cf !important;
}

.a-link:hover {
  color: #000 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

label a {
  font-weight: 600;
}

/************************************************************************************
	FORMS
************************************************************************************/
/* textarea, */
.form-control,
input[type=text],
input[type=email],
input[type=tel],
input[type=date],
input[type=number],
input[type=password] {
  background: #fff;
  width: 100%;
  height: 38px !important;
  padding: 0 10px;
  font-weight: 400;
  border-radius: 4px;
  cursor: text;
  border: 1px solid transparent;
  border-bottom: 1px solid #d8dbde;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 0 1px #fff, 0 1px 0 #fff;
  display: block;
  transition: all 0.4s;
}

textarea {
  min-height: 38px !important;
  padding-top: 7px !important;
}

/** Focus **/
.form-control:focus,
textarea:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=date]:focus,
input[type=number]:focus,
input[type=password]:focus {
  border: 1px solid #21b8cf;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
  outline: 0;
}

/** Disabled **/
.form-control[disabled],
.form-control[disabled]:hover,
textarea[disabled],
textarea[disabled]:hover,
input[type=text][disabled],
input[type=text][disabled]:hover,
input[type=email][disabled],
input[type=email][disabled]:hover,
input[type=tel][disabled],
input[type=tel][disabled]:hover,
input[type=date][disabled],
input[type=date][disabled]:hover,
input[type=number][disabled],
input[type=number][disabled]:hover,
input[type=password][disabled],
input[type=password][disabled]:hover {
  cursor: default;
  box-shadow: none !important;
}

textarea {
  width: 100%;
  border: none;
  padding: 0 10px;
}

select,
.custom-select {
  color: #333 !important;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: #dee0e3 transparent transparent #dee0e3;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  cursor: pointer;
}

select:hover,
.custom-select:hover {
  background: #f4f7fa;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  transition: all 0.4s;
}

/* custom-select avec un popin cf. create-update-sup-entity.html */
.custom-select.popin-select {
  background-position: 89% 57%;
}

select:focus,
.custom-select:focus {
  border: 1px solid #21b8cf !important;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.4s;
}

/* Look n feel des champs disabled */
input.disabled,
input:disabled:not([role=combobox]),
input[disabled]:not([role=combobox]),
input[readonly],
textarea.disabled,
textarea:disabled,
textarea[disabled],
textarea[readonly],
select.disabled,
select:disabled,
select[disabled],
select[readonly],
.ng-select .ng-select-container.disabled,
.ng-select .ng-select-container:disabled,
.ng-select .ng-select-container[disabled],
.ng-select .ng-select-container[readonly],
.ng-select.disabled .ng-select-container,
.ng-select:disabled .ng-select-container,
.ng-select[disabled] .ng-select-container,
.ng-select[readonly] .ng-select-container,
.ng-select.disabled,
.ng-select:disabled,
.ng-select[disabled],
.ng-select[readonly],
.ng-select.ng-select-disabled .ng-select-container {
  background: #e7ecf3 !important;
  border: 1px solid #e7ecf3 !important;
  color: #333 !important;
  box-shadow: none !important;
  cursor: default !important;
}

/* Champs invalid */
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f74c4c;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #f74c4c !important;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
}

/************************************************************************************
	BUTTONS
************************************************************************************/
.btn {
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  line-height: 40px;
  border: none;
  padding: 1px 20px;
  text-align: center;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}

/** Disabled **/
.btn[disabled],
.btn[disabled]:hover,
button[disabled],
button[disabled]:hover {
  cursor: default;
  box-shadow: none !important;
  opacity: 0.6 !important;
}

/** Small **/
.btn.small {
  padding: 7px 12px;
  font-weight: 600;
  line-height: 23px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 38px !important;
}

.btn.small i {
  font-size: 1rem;
}

/* Primary */
.btn.btn-primary,
.btn.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn.blue {
  color: #fff;
  background: #21b8cf;
  border: 1px solid #21b8cf;
}

.btn.btn-primary:hover,
.btn.blue:hover {
  background: #23c4dc !important;
  box-shadow: 1px 6px 6px 0 rgba(0, 0, 0, 0.1), 1px 6px 6px 0 rgba(0, 0, 0, 0.1) !important;
  transition: all 0.4s;
}

.btn-primary[disabled],
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary[disabled]:hover,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover,
.btn.blue[disabled],
.btn.blue.disabled,
.btn.blue:disabled,
.btn.blue[disabled]:hover,
.btn.blue.disabled:hover,
.btn.blue:disabled:hover {
  background: #21b8cf !important;
  box-shadow: none !important;
}

.btn-primary[disabled]:hover i,
.btn-primary.disabled:hover i,
.btn-primary:disabled:hover i,
.btn.blue[disabled]:hover i,
.btn.blue.disabled:hover i,
.btn.blue:disabled:hover i {
  color: #fff !important;
}

/* Secondary */
.btn.btn-secondary,
.btn.btn-secondary:active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn.secondary,
.btn.grey {
  color: #fff;
  background: #696d72;
  border: 1px solid #696d72;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
}

.btn.btn-secondary:hover,
.btn.secondary:hover,
.btn.grey:hover {
  background: #515457;
}

.btn-secondary[disabled],
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary[disabled]:hover,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover,
.btn.grey[disabled],
.btn.grey.disabled,
.btn.grey:disabled,
.btn.grey[disabled]:hover,
.btn.grey.disabled:hover,
.btn.grey:disabled:hover {
  background: #696d72 !important;
  box-shadow: none !important;
}

.btn-secondary[disabled]:hover i,
.btn-secondary.disabled:hover i,
.btn-secondary:disabled:hover i,
.btn.grey[disabled]:hover i,
.btn.grey.disabled:hover i,
.btn.grey:disabled:hover i {
  color: #fff !important;
}

/* Default */
.btn.btn-default,
.btn.btn-default:active,
.btn.white {
  color: #333;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #dee0e3 transparent transparent #dee0e3;
  font-weight: 400;
}

.btn.btn-default:hover,
.btn.white:hover {
  background: #f4f7fa;
  color: #000;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.btn.btn-default:focus,
.btn.white:focus {
  background: #fff;
  color: #000;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.btn.btn-default[disabled],
.btn.btn-default.disabled,
.btn.btn-default:disabled {
  border: 1px solid #fff;
}

.btn.btn-default[disabled]:hover,
.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover {
  color: #333;
  background: #fff !important;
  box-shadow: none !important;
}

/* Boutons de la toolbar
 * Boutons contenant juste une icone
 * Exemple : Exporter les resultats en pdf dans la Toolbar
 */
.btn-action {
  background: #fff;
  color: #333 !important;
  /* border-width: $sy-btn-default-border-width;
  border-style: $sy-btn-default-border-style;
  border-color: $sy-btn-default-border-color; */
  border: 1px solid transparent;
  font-size: 14px;
  padding: 0 12px;
}

.btn-action:hover {
  background: #f4f7fa;
}

.btn-action:hover,
.btn-action:hover a,
.btn-action a:hover {
  text-decoration: none !important;
}

.btn-action:focus {
  background: #fff !important;
  color: #000;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.btn-action i {
  color: #626262;
}

.btn-action:hover i {
  color: black;
}

.btn-action[disabled],
.btn-action.disabled,
.btn-action:disabled,
.btn-action[disabled]:hover,
.btn-action.disabled:hover,
.btn-action:disabled:hover {
  cursor: default;
  opacity: 0.6;
}

.btn-action[disabled]:hover i,
.btn-action.disabled:hover i,
.btn-action:disabled:hover i {
  color: #626262;
}

/****/
.btn.full {
  width: 100%;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.grid-bottom span.btn.active {
  background: #21b8cf;
  color: #fff;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  box-shadow: none;
}

/* Pour caler exactement les btn.small au box-shadow des input */
.input-group .btn.small {
  box-shadow: none !important;
  height: 37px;
  margin-top: 1px;
}

/************************************************************************************
	LISTES
************************************************************************************/
.list-primary {
  list-style: none;
  padding: 0;
}

.list-primary li {
  padding-left: 20px;
  margin-bottom: 4px;
}

.list-primary li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 12px;
  color: #696d72;
  display: inline-block;
  margin-left: -20px; /* idem li */
  width: 20px; /* idem li */
}

/************************************************************************************
	.CONTAINER
************************************************************************************/
.container {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* General */
.no-app .container {
  z-index: 2;
  position: relative;
}

.app .container {
  display: block !important;
  width: 100% !important;
  background: none !important;
  padding: 0;
  overflow: hidden;
  clear: both;
}

[class*=careweb-container] {
  margin: 0 auto;
  position: relative;
}

.app [class*=careweb-container] {
  padding-top: 30px;
  z-index: 10;
}

/* Small  */
.small-careweb-container {
  width: 430px;
  padding: inherit;
}

/* Medium */
.med-careweb-container {
  width: 80%;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 110px;
}

.medium-careweb-container {
  width: 50%;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 110px;
}

/* Large */
.large-careweb-container {
  width: 950px;
}

/* X-Large */
.x-large-careweb-container {
  width: 100%;
}

@media (max-width: 767px) {
  .small-careweb-container,
  .med-careweb-container,
  .medium-careweb-container,
  .large-careweb-container {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .x-large-careweb-container {
    width: 100%;
  }
  .x-large-careweb-container .form-content {
    border-radius: 0;
  }
}
/* Full */
.inner-container {
  padding: 25px 35px 35px;
  background: #f1f4f8;
  display: block;
  border-radius: 4px;
  clear: both;
}

/* .inner-container .row {
    margin-bottom: 10px;
} */
.required-container .required-fields {
  font-size: 0.75rem;
  color: #333;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

/************************************************************************************
	MAIN-CONTENT
************************************************************************************/
/* Conteneur principal des pages de contenu */
.main-content {
  padding: 30px 55px 15px 55px;
}

@media (max-width: 991px) {
  .main-content {
    padding: 15px;
  }
}
/************************************************************************************
	HEADER
************************************************************************************/
/* NO APP */
/* Affichage du header-lang dans l'application */
.no-app header {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  clear: both;
}

.no-app .logo {
  background: url(../img/logo-caw-square-white.png) no-repeat center top;
  background-size: 186px 130px;
  padding: 45px;
}

.login .logo {
  background: url(../img/logo-caw-square-white.png) center top/190px 69px no-repeat;
  background-position-y: 20px;
  padding: 0;
}

.login .logo header {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  clear: both;
}

.no-app header .baseline {
  line-height: 50px;
  font-weight: 400;
  color: #41eaff;
  font-size: 13px;
  margin-left: 0;
}

.no-app header nav {
  float: right;
}

.no-app .home-link {
  padding: 15px;
  font-size: 13px;
}

.no-app .hide-no-app {
  display: none;
}

/* APP */
.app .header {
  display: block !important;
  position: relative;
  margin: 0 !important;
  width: 100%;
  z-index: 100;
}

.app .header-top {
  display: block;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #4a636e;
  background: #494c4f url("../img/logo-caw-line-white.png") no-repeat scroll 34px 11px/201px 63px;
}

/* Element vide situé sous le logo SY permettant de simuler un clic sur ce logo. */
.app .baseline {
  display: block;
  width: 180px;
  height: 100%;
  text-decoration: none;
}

.button-width-180 {
  width: 180px;
}

.button-width-300 {
  width: 300px;
}

.button-width-280 {
  width: 280px;
}

.button-width-270 {
  width: 270px;
}

/************************************************************************************
	FOOTER
************************************************************************************/
/* APP */
.app footer {
  display: block;
  z-index: 3;
  clear: both;
  overflow: hidden;
  height: 65px;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  /* margin-top: 35px; */
}

.app footer ul {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

.app footer ul li {
  display: block;
  position: relative;
  float: left;
  margin-right: 10px;
  line-height: 15px;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: inherit;
  color: #666;
}

.app footer ul li a {
  color: #666;
  text-decoration: none;
}

.app footer ul li a:hover {
  color: #333;
  text-decoration: underline;
}

/************************************************************************************
	FORM CONTENT
************************************************************************************/
.form-content {
  padding: 25px 35px 30px;
  background: #f1f4f8;
  display: block;
  border-radius: 4px;
  clear: both;
}

.form-content-top-pad10 {
  padding: 10px 35px 30px;
  background: #f1f4f8;
  display: block;
  border-radius: 4px;
  clear: both;
}

.full-careweb-container .form-content {
  padding: 0;
}

.no-bg .form-content {
  background: #f1f4f8;
}

.form-content .form-title {
  padding-bottom: 1rem;
}

.form-content .text-title,
.form-content .img-title {
  text-align: right;
  font-weight: 600;
}

/* Textes */
.form-content .txt {
  padding: 30px;
  font-size: 14px;
  font-weight: 600;
}

.form-content .txt p {
  color: #333;
}

.form-content fieldset {
  margin: 0 0 0;
}

.form-content fieldset .row h2 {
  margin: 0 0 10px;
}

.form-content .confirmation fieldset .row h2 {
  margin: 0 0 5px;
}

.form-content .row.action {
  margin-bottom: 0;
  margin-top: 0;
}

.form-content .action-border {
  padding-top: 30px;
  border-top: 1px solid #caced2;
}

.form-content .action-no-border {
  padding-top: 30px;
}

@media (max-width: 991px) {
  .form-content {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  .form-content {
    border-radius: 0 !important;
  }
  .form-content .text-title {
    text-align: left;
  }
}
div.ng2-tag-input {
  border: none;
  box-shadow: unset !important;
  background-color: white;
}

input.ng2-tag-input__text-input {
  font-family: unset !important;
}

input.ng2-tag-input__text-input,
input.ng2-tag-input__text-input:focus {
  box-shadow: none;
  border: none;
}

/* Form-content dans les modales */
.modal-dialog .form-content {
  background: #f1f4f8 !important;
  padding: 0 !important;
}

/** Style pour les ngx-dropdown */
.basic-link-dropdown:hover,
.basic-link-dropdown:focus {
  text-decoration: none;
}

.basic-link-dropdown i,
.basic-link-dropdown .fa,
.basic-link-dropdown .icon-myfont-arrowdown {
  color: #626262;
}

.basic-link-dropdown:hover i,
.basic-link-dropdown:hover .fa,
.basic-link-dropdown:hover .icon-myfont-arrowdown {
  color: black;
}

/** Fix affichage de l'icone d'info dans un champs. */
.field.tag-input-popin a {
  margin-top: 8px;
}

/** Décale legèrement à gauche l'icone d'info dans le textarea insurance. */
.insurance-popin > .popin {
  margin-right: 9px;
  margin-top: 2px;
}

/** Croix pour fermer les popups. */
.modal-header button.exit {
  border: none;
}

.modal-header button.exit:hover {
  color: #f00;
}

showdown table td {
  border: 1px solid #e7ecf3;
  padding: 8px;
  text-align: left;
}

showdown table th {
  visibility: hidden;
}

careweb-modal-discussions showdown > p {
  margin-bottom: 0px;
}

/**
 * Tableau de bord
 * Affichage sur 5 colonnes
 */
.col-lg-15 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-lg-15 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
careweb-counter div {
  font-size: 48px;
  font-weight: 300;
  top: 50%;
  position: absolute;
  left: 0;
}

@media (max-width: 1366px) {
  .tile-item {
    padding: 20px;
    min-height: 200px;
  }
  careweb-dropfile-tile.tile-item {
    padding: 10px;
  }
}
@media (max-width: 991px) {
  .tile-item {
    min-height: 180px;
  }
}
@media (max-width: 1366px) {
  .tile-item .title {
    font-size: 1rem;
    line-height: 20px;
  }
  .tile-item .subindicator {
    font-size: 0.875rem;
    line-height: 16px;
  }
  careweb-counter div {
    font-size: 38px;
    top: 40%;
  }
  .tile-item a {
    font-weight: 600;
  }
  .tile-item .gauge {
    width: 130px;
    top: 30px;
    margin-left: -65px;
  }
  careweb-dropfile-tile a {
    margin-top: 100px;
  }
  a.dropfile-link {
    font-size: 0.875rem !important;
    line-height: 18px !important;
  }
}
@media (max-width: 991px) {
  .tile-item a {
    font-size: 0.75rem;
  }
  .tile-item .number img {
    width: 8%;
  }
}
@media (max-width: 767px) {
  .tile-item {
    min-height: 120px;
    margin-bottom: 15px;
    border-radius: 4px;
  }
  .tile-item .title {
    font-size: 1rem;
    line-height: 20px;
  }
  .tile-item .subtitle {
    display: inline;
    margin-left: 5px;
  }
  .tile-item .subindicator {
    display: none;
  }
  .tile-item .gauge {
    width: 100px;
    top: -10px;
    margin-left: -50px;
    margin-bottom: 27px;
  }
}
/************************************************************************************
	BACKGROUNDS
************************************************************************************/
/* No Background (for container full) */
.no-bg {
  background-color: #f1f4f8 !important;
}

/* Background image */
.bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #20b0c6 no-repeat center fixed;
  background-size: cover;
  transition: background 200ms ease-out;
}

.bg ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bg img {
  width: 100%;
}

.bg .overlay {
  position: relative;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+28,20abc0+100&0+28,1+100 */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(28%, rgba(30, 87, 153, 0)), color-stop(100%, rgb(32, 171, 192)));
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  /* W3C */
}

/** BACKGROUND COLOR **/
.bg-color,
.bg-color .modal-title,
.bg-color .close {
  color: #fff !important;
}

.bg-color .close {
  text-shadow: 0 1px 0 #000 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-primary {
  background-color: #21b8cf !important;
}

.bg-secondary {
  background-color: #696d72 !important;
}

.bg-default {
  background-color: #fff !important;
}

.bg-success {
  background-color: #5db75d !important;
}

.bg-info {
  background-color: #31728e !important;
}

.bg-warning {
  background-color: #ff9317 !important;
}

.bg-danger {
  background-color: #f74c4c !important;
}

/************************************************************************************
	TEXT COLOR
************************************************************************************/
.text-primary {
  color: #21b8cf !important;
}

.text-secondary {
  color: #595959 !important;
}

.text-success {
  color: #5db75d !important;
}

.text-info {
  color: #31728e !important;
}

.text-warning {
  color: #ff9317 !important;
}

.text-danger {
  color: #f74c4c !important;
}

/************************************************************************************
	BORDERS surcharge couleurs Bootstrap
************************************************************************************/
.border-top {
  border-top: 1px solid #caced2 !important;
}

.border-top-dashed {
  border-top: 1px dashed #caced2 !important;
}

.border-bottom {
  border-bottom: 1px solid #caced2 !important;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #caced2 !important;
}

.border-right {
  border-right: 1px solid #caced2 !important;
}

.border-right-dashed {
  border-right: 1px dashed #caced2 !important;
}

.border-left {
  border-left: 1px solid #caced2 !important;
}

.border-left-dashed {
  border-left: 1px dashed #caced2 !important;
}

/************************************************************************************
	BORDER COLOR
************************************************************************************/
.border-primary {
  border-color: #21b8cf !important;
}

.border-secondary {
  border-color: #696d72 !important;
}

.border-success {
  border-color: #5db75d !important;
}

.border-info {
  border-color: #31728e !important;
}

.border-warning {
  border-color: #ff9317 !important;
}

.border-danger {
  border-color: #f74c4c !important;
}

/************************************************************************************
	SHADOWED - Ombre portée sur les conteneurs
************************************************************************************/
/* Permet d'appliquer une ombre au .form-content de manière spécifique (ex : Pages du compte)
 * Ce style d'ombre est directement appliquée aux éléments suivants :
 * .tile-item
 * .ag-theme-balham .ag-root
 * .notifs .container
*/
.shadowed {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  background-color: #ffffff;
}

/************************************************************************************
	MARGIN
************************************************************************************/
/* TODO Style à remplacer par m-0 de bootstrap4 */
.no-margin {
  margin: 0;
}

/* TODO Style à remplacer par mb-0 de bootstrap4 */
.no-margin-bt {
  margin-bottom: 0 !important;
}

.auto-margin {
  margin: auto;
}

.auto-margin-left {
  margin-left: auto;
}

.no-side-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.top-margin-5 {
  margin-top: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.hidden-visibility {
  visibility: hidden;
}

.top-margin-minus-10 {
  margin-top: -10px;
}

.top-margin-7 {
  margin-top: 7px;
}

.left-margin-30 {
  margin-left: 30px;
}

.top-margin-30 {
  margin-top: 30px;
}

/************************************************************************************
	MISC
************************************************************************************/
.pointer {
  cursor: pointer;
}

.pointer .fa {
  color: #21b8cf;
  margin-left: 10px;
}

.not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.relative {
  position: relative;
}

.textAreaNoResize {
  resize: none;
}

.full-height {
  height: 100% !important;
}

.upload-filename-max-width {
  max-width: 540px;
}

.version {
  font-size: 10px;
  color: #333;
  /* border-top: 1px solid #ddd; */
}

.version p {
  margin: 0;
  text-align: right;
  color: #626262;
}

.version b {
  font-weight: 400 !important;
}

/* BORDERS */
/* RESET */
.app ul {
  list-style-type: none;
}

.app .main {
  margin-top: 90px;
}

.container-fluid {
  padding: 0px;
}

/* UNDERLINE */
.underline {
  text-decoration: underline;
}

.text-align-center {
  text-align: center;
}

.margin-left-10 {
  margin-left: 10px;
}

/****/
dt {
  font-weight: 600;
}

.form-content-detail-grid {
  background-color: #f1f4f8;
  border-radius: 5px;
  padding: 1em;
}

a, button, .btn {
  text-decoration: none;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none !important;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  flex-basis: fit-content !important;
}

.col-auto {
  max-width: 100%;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

/************************************************************************************
	STATUS @TODO: to filter
************************************************************************************/
.status {
  font-weight: 600;
}

.status.primary,
.status.blue {
  color: #21b8cf;
}

.status.secondary,
.status.grey {
  color: #696d72;
}

.status.success,
.status.green {
  color: #5db75d;
}

.status.info,
.status.blue-oil {
  color: #31728e;
}

.status.danger,
.status.red {
  color: #f74c4c;
}

.status.warning,
.status.orange {
  color: #ff9317;
}

.status.black {
  color: #000;
}

/*** PUCE RAPPROCHEMENT BANCAIRE ***/
.bullet-rapp-count {
  background: #27a344;
  border-radius: 1.3em;
  -moz-border-radius: 1.3em;
  -webkit-border-radius: 1em;
  color: #ffffff !important;
  display: inline-block;
  line-height: 2.5em;
  text-align: center;
  width: 2.5em;
}

/** Puces rondes **/
.status.bullet.alone:after,
.bullet-label:before {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: -1px;
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

div.ui-grid-cell input {
  padding: 6px 12px;
  border-radius: 4px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  width: 90%;
  margin: auto;
  margin-top: 5px;
}

.status.bullet.alone:after {
  margin-right: 0;
  margin-left: 5px;
  margin-bottom: 0;
}

.notif-bck.blue,
.status.bullet.blue:after,
.bullet-label.blue:before {
  background-color: #21b8cf;
}

.notif-bck.grey,
.status.bullet.grey:after,
.bullet-label.grey:before {
  background-color: #696d72;
}

.notif-bck.blue-oil,
.status.bullet.blue-oil:after,
.bullet-label.blue-oil:before {
  background-color: #31728e;
}

.notif-bck.green,
.status.bullet.green:after,
.bullet-label.green:before {
  background-color: #5db75d;
}

.notif-bck.orange,
.status.bullet.orange:after,
.bullet-label.orange:before {
  background-color: #ff9317;
}

.notif-bck.red,
.status.bullet.red:after,
.bullet-label.red:before {
  background-color: #f74c4c;
}

.notif-bck.black,
.status.bullet.black:after,
.bullet-label.black:before {
  background-color: #000;
}

/************************************************************************************
	SPINNER
************************************************************************************/
ng-http-loader #spinner[_ngcontent-c1] {
  background-color: rgba(0, 0, 0, 0.6);
}

/************************************************************************************
	INPUTS
************************************************************************************/
/* Ajout d'une '*' quand le champ est required */
.form-group.required label:after,
.form-group label span.required:after {
  font-weight: normal;
  font-size: 14px;
  content: "*";
  margin-left: 4px;
  color: #333;
  font-weight: 600;
}

label {
  width: 100%;
  color: #333;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .form-group {
    margin-bottom: 0.7rem;
  }
}
/************************************************************************************
	MESSAGES D'ERREUR
************************************************************************************/
/*
.invalid-feedback 	--> ex : Modale Création d'un établissement
.error-messages	 	--> ex : Page "Facture en cours de saisie"
.error				--> ex : Page "Valider ma facture"
*/
.invalid-feedback,
.error-messages.ng-active,
div.error,
label.error,
div.error-field-input {
  color: #f74c4c !important;
  font-size: 80% !important;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.invalid-feedback {
  margin-top: -10px;
  margin-bottom: 15px;
  color: #f74c4c;
  display: block;
}

.form-group.field-error.required label:after {
  color: #f74c4c;
}

.field-error label {
  color: #f74c4c;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.checkbox label.error {
  position: inherit;
}

.field-error input,
.field-error textarea,
.field-error .dropdown-toggle {
  border: 1px solid #f74c4c !important;
  display: block;
}

/************************************************************************************
	COMPOSANTS
************************************************************************************/
.warning-sign {
  color: orange;
  position: absolute;
  top: 3px;
  right: -5px;
}

.position-relative {
  position: relative;
}

li.del:hover div.notif-item.read > a:before {
  display: none;
}

md-notif-item > div.read :first-child {
  cursor: default;
}

.notif.red:before {
  color: #f74c4c;
}

.notif.blue:before {
  color: #21b8cf;
}

.notif.green:before {
  color: #5db75d;
}

.notif.blue-oil:before {
  color: #1da4b9;
}

.notif.fushia:before {
  color: #db0073;
}

@media (max-width: 767px) {
  .notifs-container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .notifs {
    max-width: 100%;
  }
}
/* B4 .badge */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  background-color: #21b8cf;
  color: #fff;
}

.badge-secondary {
  background-color: #696d72;
  color: #fff;
}

.badge-default {
  background-color: #fff !important;
  color: #333;
  border: 1px solid #caced2;
}

.badge-success {
  background-color: #5db75d !important;
  color: #fff;
}

.badge-info {
  background-color: #31728e !important;
  color: #fff;
}

.badge-warning {
  background-color: #ff9317 !important;
  color: #fff;
}

.badge-danger {
  background-color: #f74c4c !important;
  color: #fff;
}

/************************************************************************************
	BELOW FIELD
************************************************************************************/
.below-field,
a.below-field {
  color: #333;
  font-weight: 600;
  /* text-decoration: $sy-link-hover-decoration; */
  font-size: 12px;
  line-height: 20px;
  display: block;
  margin-top: 3px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.below-field:hover,
a.below-field:hover {
  color: #20b0c6;
}

/** Accept field */
.accept-field {
  padding: 20px;
}

.accept-field.no-bg {
  padding: 0;
  background-color: inherit !important;
}

.accept-field .checkbox,
.accept-field .radio {
  margin: 0;
}

.accept-field label {
  font-size: 0.8125rem;
  font-weight: 600 !important;
  padding-left: 10px;
  width: auto;
  cursor: default;
}

.accept-field.no-bg label {
  color: #333;
}

.accept-field a {
  float: right;
  text-decoration: underline;
  color: #20b0c6;
  font-size: 0.8125rem;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.accept-field a:hover {
  color: #333;
}

.accept-field input[type=checkbox] {
  cursor: pointer;
}

/* Les boutons avec une icone font-awesome réagissant au survol de la souris */
label.btn .fa:hover {
  color: #000;
}

/************************************************************************************
	AFFICHAGE DE L'ICONE AIDE
************************************************************************************/
.field {
  position: relative;
}

.field input {
  position: relative;
  z-index: 1;
}

.field.field-with-info input {
  padding-right: 40px;
}

.popin-btn-container {
  height: 40px;
  position: absolute;
  right: -13px;
  top: -13px;
  width: 40px;
  text-align: center;
  /* line-height: 40px; */
}

h3 .popin-btn-container,
h2 .popin-btn-container,
h1 .popin-btn-container,
label .popin-btn-container,
th .popin-btn-container {
  position: inherit;
  right: inherit;
  top: inherit;
  vertical-align: baseline;
}

.popin-btn-container i {
  color: #21b8cf;
  font-size: 1.5rem;
  /* line-height: 40px; */
}

/* icone info dans un th de tableau */
.ui-grid-like th .popin-btn-container i {
  color: rgba(255, 255, 255, 0.75);
}

.ui-grid-like th .popin-btn-container i:hover {
  color: rgb(255, 255, 255);
}

/* icone info dans un champs */
.field .popin-btn-container i {
  line-height: 40px;
}

.popin-btn-container i:hover {
  color: #23c4dc;
}

h3 .popin-btn-container i,
label .popin-btn-container i,
th .popin-btn-container i {
  vertical-align: sub;
}

.label-popin h3 {
  display: inline-block;
}

.label-popin h3 .info,
.label-popin label .info {
  position: inherit;
  right: inherit;
  top: inherit;
  display: inherit;
  height: inherit;
  vertical-align: middle;
  text-decoration: none;
}

.label-popin .info,
.field .info {
  position: absolute;
  z-index: 2;
  right: 12px;
  top: 10px;
  display: block;
  width: 17px;
  height: 17px;
}

.select-popin .info {
  right: 45px;
  top: 12px;
}

.label-popin .info {
  right: 28px;
}

.label-popin label {
  padding-right: 35px;
  line-height: 35px;
}

/************************************************************************************
	TOASTERS
************************************************************************************/
.toast-container .toast {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.toast-info {
  background-color: #20b0c6;
}

.toast-warning {
  background-color: #ff9317;
}

.toast-error {
  background-color: #f74c4c;
}

/************************************************************************************
	SCROLLBAR override
************************************************************************************/
.ngsb-wrap .ngsb-scrollbar .ngsb-track {
  background: rgba(147, 152, 155, 0.8);
}

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos .ngsb-thumb {
  background: rgba(75, 71, 72, 0.8);
}

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos:hover .ngsb-thumb {
  background: rgba(75, 71, 72, 0.8);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos:active {
  background: rgba(147, 152, 155, 0.6);
  filter: "alpha(opacity=60)";
  -ms-filter: "alpha(opacity=60)";
}

/** TABLE */
table th {
  font-weight: 600;
}

table > tbody tr > td.delete-col {
  padding-left: 0;
  line-height: 35px;
  width: 20px;
  font-size: 1rem;
  cursor: pointer;
}

/************************************************************************************
	FORMULAIRE DYNAMIQUE
************************************************************************************/
careweb-section .form-group {
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

careweb-section input[type=number] {
  text-align: right;
}

careweb-section textarea {
  resize: none;
}

careweb-section p.input-group {
  margin-bottom: 0;
}

careweb-section label {
  margin-bottom: 2px;
}

div.section-form-row {
  margin-bottom: 0 !important;
  margin-left: -15px !important;
  margin-right: 15px !important;
}

/************************************************************************************
	PDF VIEWER
************************************************************************************/
/** TOOLBAR **/
.pdf-toolbar {
  margin-bottom: 15px;
}

.pdf-toolbar .item-control.text {
  padding-top: 7px;
  font-weight: 600;
}

/** VIEWER COMPONENT **/
.pdf-viewer {
  width: 100%;
  background-color: #494c4f;
  border: none;
  border-radius: 6px;
}

.pdf-viewer .pdf-container {
  width: 100%;
  overflow: auto;
  padding: 25px 0;
  text-align: center;
  max-height: 1000px;
  color: #fff;
}

.pdf-viewer .pdf-container .pdf-document .pdf-canvas {
  -o-box-shadow: 0px 0px 30px 0px #222;
  box-shadow: 0px 0px 30px 0px #222;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#222, Direction=NaN, Strength=30);
  border: 1px solid #000;
}

.pdf-viewer .pdf-container .pdf-document .rotate0 {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pdf-viewer .pdf-container .pdf-document .rotate90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.pdf-viewer .pdf-container .pdf-document .rotate180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.pdf-viewer .pdf-container .pdf-document .rotate270 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

/** Logo société */
.legalentity-logo {
  width: 60px;
  height: 45px;
}

@media (max-width: 767px) {
  careweb-pdf-viewer {
    margin-top: 35px;
  }
}
/************************************************************************************
	TABLEAUX
************************************************************************************/
.table th,
.table td {
  padding: 0.75rem 0.4rem;
}

/************************************************************************************
	TABLEAU DE L'ECRAN : INFO COMPLEMENTAIRE SOCIETE
************************************************************************************/
.table-header {
  width: 100%;
  margin-bottom: 30px;
}

.table th,
.table td,
.table-header tbody tr {
  border-top: 1px solid #caced2;
  background: #fff;
}

.table-header tbody tr td {
  background-color: #fff;
  padding: 6px 0;
}

.table-header thead tr {
  height: 35px;
}

.table-header thead tr th {
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 15px;
  background-color: #787c81;
  text-align: left;
  padding-left: 1.1rem;
}

.table-header thead tr th:first-child {
  border-radius: 4px 0 0 0;
}

.table-header thead tr th:last-child {
  border-radius: 0 4px 0 0;
}

.table-header div.form-check {
  margin-left: 55px;
}

.table-label-checkbox {
  width: auto;
}

/* .table-header tbody tr td input[type='text'],
.table-header tbody tr td ol button {
    border: $sy-form-border;
} */
.table-header careweb-error-messages div {
  position: relative;
}

.table-header tbody tr td textarea {
  resize: vertical;
}

/**
 * Table ayant le même look qu'une grille ui-grid.
 * Exemple :
 * <table class="ui-grid-like">
 * 		<thead>
 *			<tr>
 *				<th>Titre colonne</th>
 *				...
 *			</tr>
 *		</thead>
 *		<tbody>
 *			...
 *		</tbody>
 * </table>
 */
.ui-grid-like {
  width: 100%;
}

.ui-grid-like thead tr {
  height: 35px;
}

.ui-grid-like th {
  line-height: 15px;
  font-weight: 700;
  font-size: 14px;
  background-color: white;
  padding: 15px;
  color: black;
  /* border-right: 1px solid rgb(135, 133, 134); */
  border-bottom: 1px solid rgb(135, 133, 134);
}

.ui-grid-like th:first-child {
  border-radius: 7px 0 0 0;
}

.ui-grid-like th:last-child {
  border-radius: 0 7px 0 0;
  border-right: 0;
}

.ui-grid-like th:first-child:last-child {
  border-radius: 7px 7px 0 0;
  border-right: 0;
}

.ui-grid-like tbody tr {
  border-top: 1px solid #dee0e3;
}

.ui-grid-like tbody tr td {
  padding: 10px 15px;
  background-color: #fff;
}

/************************************************************************************
	TABLEAU DE L'ECRAN UTILISATEUR
************************************************************************************/
.table-user-info {
  margin-bottom: 30px;
}

.table-user-info thead tr th .popin-btn-container {
  line-height: 0;
}

.table-user-info thead tr th .popin-btn-container i {
  line-height: 0;
}

.table-user-info thead tr th a {
  text-decoration: none;
  vertical-align: middle;
}

.input-menu-container .btn {
  border-radius: 0;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  margin-top: -8px;
  box-shadow: none;
}

.input-menu-container .fa-chevron-circle-down {
  color: #626262;
}

.input-item li a,
.input-item li a:not([href]) {
  color: #333;
}

/** Dropdown actions dans les tableaux **/
.input-item.dropdown-menu-right {
  right: -35px !important;
  top: -3px;
  left: initial;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.input-item.dropdown-menu-right a.dropdown-item {
  padding: 10px;
}

.input-item.dropdown-menu-right a.dropdown-item:hover {
  background: #eef1f6;
}

.link-ubl-report {
  color: #f74c4c !important;
  text-decoration: underline;
}

/************************************************************************************
	TITLED SECTION
************************************************************************************/
/* Section possédant un titre
 * Exemple :
 * <section class="titled-section">
 *		<h1>Le titre de la section</h1>
 *		...
 * </section>
 */
.titled-section > h1 {
  color: #333;
}

.titled-section > h1 {
  font-size: 1.75rem;
  font-weight: 700;
}

.titled-section + .titled-section {
  margin-top: 1em;
}

/* Bloc clé-valeur mettant en valeur la clé.
 * Exemple :
 * <dl class="inline-labeled-value">
 * 		<dt>Une clé</dt>
 * 		<dd>Une valeur</dd>
 * </dl>
 */
dl.inline-labeled-value {
  margin: 0 0 5px 0;
}

dl.inline-labeled-value .badge {
  margin-right: 5px;
}

dl.inline-labeled-value dt,
dl.inline-labeled-value dd {
  display: inline-block;
}

dl.inline-labeled-value dd {
  color: #333;
  font-weight: 600;
}

dl.inline-labeled-value dd {
  margin-left: 0.4em;
}

/************************************************************************************
	BARRE D'ACTION
************************************************************************************/
/* Barre d'action en bas des pages
 * Exemple :
 * <div class="button-bar">
 *		<button...>
 * </div>
 */
.button-bar {
  text-align: right;
}

.button-bar .btn {
  margin-left: 7px;
}

@media (max-width: 767px) {
  .button-bar {
    padding-left: 0;
    padding-right: 0;
  }
  .button-bar .btn {
    margin: 0 0 5px 0;
    width: 100%;
  }
}
/************************************************************************************
	Colonne de type numérique
************************************************************************************/
/* .cell-number {
    text-align: right;
} */
span.error-messages {
  color: #f74c4c;
  font-size: 11px;
  margin-left: 5px;
}

/************************************************************************************
	SIDEBAR
************************************************************************************/
/** Surcharge des filtres */
.checkbox-filter .input-group-btn .btn i {
  position: absolute;
  top: 12px;
  right: 13px;
}

.checkbox-filter .input-group-btn .btn i:hover {
  color: #fff;
}

/** Champs + Bouton Calendar */
.input-group .form-control[type=text][bsdatepicker] {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39);
}

.input-group .form-control[type=text][bsdatepicker]:focus {
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.checkbox-filter .input-group-btn .btn,
.input-group-btn .btn.btn-calendar,
.input-group-btn .btn.btn-calendar:active {
  height: 36px;
  width: 35px !important;
  background-color: #696d72;
  color: #fff;
  margin-top: 1px;
  box-shadow: none !important;
}

/** Surcharge datepicker ngx-bootstrap */
.bs-datepicker {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background: #f1f4f8;
  border-radius: 4px;
}

.bs-datepicker-container {
  padding: 0;
  z-index: 15;
}

.bs-datepicker-body {
  border-radius: 0;
  border: none;
}

.bs-datepicker-head {
  background-color: #494c4f;
  border-left-top-radius: 4px;
  border-right-top-radius: 4px;
}

.bs-datepicker-head button {
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}

/* Icones fleches */
.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  line-height: 0.3;
}

/* Conteneurs des Jours : Lun Mar Mer Jeu etc. */
.bs-datepicker-body table th,
.bs-datepicker-body table td {
  font-size: 0.875rem;
  color: #737373;
  font-weight: 600;
}

/* Conteneur des Numero de jours */
.bs-datepicker-body table td:not(.week) {
  background-color: #fff;
  color: #333 !important;
  font-weight: 600;
}

.bs-datepicker-body table td:not(.week):hover {
  color: #333 !important;
}

.bs-datepicker-body table td span {
  font-size: 0.875rem;
  border-radius: 4px;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: #b3b2b2;
  font-weight: 400;
}

.bs-datepicker-body .is-other-month {
  color: #b3b3b3 !important;
  font-weight: 400;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e0e7f0;
  color: #333;
}

.dropdown-grey-hover:hover {
  background-color: #eef1f6;
}

/* Jour selectionné */
.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
  color: #fff;
  background: #21b8cf;
}

/************************************************************************************
	CHECKBOX
************************************************************************************/
/** Surcharge awesome-bootstrap-checkbox */
.abc-checkbox {
  padding-bottom: 6px;
}

.abc-checkbox-primary input[type=checkbox]:checked + label::before {
  background-color: #21b8cf;
  border-color: #21b8cf;
}

/************************************************************************************
	ACCORDION - SIDEBAR FILTERS
************************************************************************************/
/** Surcharge pour le composant angular-material-accordion */
.accordion-filter .mat-expansion-panel {
  background-color: #55595c;
}

.accordion-filter .mat-expansion-panel.mat-expanded,
.accordion-filter .mat-expansion-panel-content,
.accordion-filter .mat-expansion-panel:hover {
  background-color: #3d3f42;
  transition: all 0.3s ease 0s;
}

.accordion-filter .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.accordion-filter .mat-expansion-indicator,
.accordion-filter .mat-expansion-indicator::after,
.accordion-filter .mat-expansion-panel-header-description {
  color: rgba(255, 255, 255, 0.7);
}

.accordion-filter .accordion-badge {
  margin-left: auto;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  background-color: #757a7f;
  height: 22px;
  width: 22px;
  border-radius: 4px;
  text-align: center;
  align-self: center;
}

.accordion-filter .mat-expanded .accordion-badge,
.accordion-filter .mat-expansion-panel:hover .accordion-badge {
  background-color: #23c4dc;
}

.accordion-filter mat-expansion-panel .icon-myfont-exit {
  background-color: #696d72;
  border-left: 1px solid #494c4f;
}

.accordion-filter mat-expansion-panel {
  border: none;
  margin-top: 1px !important;
}

.accordion-filter mat-panel-title {
  display: block;
  height: 50px;
  padding-left: 0;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.accordion-content label {
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
}

.accordion-filter mat-expansion-panel .filter-label {
  background-color: #55595c;
  color: rgba(255, 255, 255, 0.85);
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -webkit-fill-available;
}

.accordion-filter mat-expansion-panel careweb-filter-label:first-of-type {
  display: block;
  margin-top: 20px;
}

/** Surcharge les typeahead ngx-boostrap se trouvant dans un accordeon de filtre. **/
.accordion-filter .dropdown-item.active {
  background-color: #e0e7f0;
  color: #333;
}

.accordion-filter .dropdown-item:hover {
  cursor: pointer;
  background-color: #eef1f6;
}

/** Surcharge les champs se trouvant dans un accordeon de filtre. **/
.accordion-filter input[type=text],
.accordion-filter input[type=number],
.accordion-filter .input-group .form-control[type=text][bsdatepicker] {
  box-shadow: none;
}

/** Surcharge les button group se trouvant dans un accordeon de filtre. **/
.accordion-filter .checkbox-filter .input-group-btn .btn,
.accordion-filter .input-group-btn .btn.btn-calendar {
  height: 38px;
  margin-top: 0;
}

/************************************************************************************
	TOOLTIPS
************************************************************************************/
.tooltip.show {
  opacity: 1;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-color: rgba(65, 65, 65, 0) !important;
}

.tooltip-inner {
  max-width: 250px;
  padding: 0.35rem 0.65rem;
  color: #fff;
  background-color: rgba(65, 65, 65, 0.95);
  border-radius: 0.3rem;
}

/************************************************************************************
	CUSTOM CHECKBOX - Bootstrap component
************************************************************************************/
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #21b8cf;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #21b8cf;
}

/************************************************************************************
	NAV TABS
************************************************************************************/
/* Navigation (ex : Page Mon entreprise) */
.nav-tabs .nav-link {
  font-size: 1rem;
  color: #949494;
  font-weight: 600;
  border: 1px solid transparent;
  border-bottom: 2px solid #caced2;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  transition: all 0.4s;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e0e7f0 #e0e7f0 #e0e7f0;
  background: #e0e7f0;
  color: #333;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #333;
  background-color: #fff;
  border-color: #dee0e3 #dee0e3 transparent;
  border-bottom: 2px solid #21b8cf;
  cursor: default;
}

@media (max-width: 767px) {
  .nav-tabs .nav-item {
    flex-basis: 100%;
  }
  .nav-tabs .nav-link {
    padding: 0.8rem 1rem;
    text-transform: uppercase;
    font-size: 0.8125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
/************************************************************************************
	TABS CONTAINER
************************************************************************************/
/* Conteneurs des formulaires liés à des .nav-tabs (ex : Page Mon entreprise) */
.tabs-container {
  background: #f1f4f8;
  /* border: 1px solid $sy-main-border-color-ultra-light; */
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 15px 0;
}

.tabs-container h2 {
  font-size: 24px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .tabs-container {
    padding: 0;
  }
}
/************************************************************************************
	LISTES DEROULANTES - .DROPDOWN
************************************************************************************/
/** Button **/
.dropdown-button {
  height: 38px !important !important;
  transition: all 0.4s;
}

.dropdown-button:focus {
  border: 1px solid #21b8cf;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
  outline: 0;
}

/** Toggle **/
.dropdown-toggle {
  width: 100%;
  position: relative;
  border-radius: 0;
  display: block;
  background: #fff;
  box-shadow: none;
  height: 40px;
  border: none;
  font-weight: 600;
  padding-right: 13px;
}

.dropdown-toggle .bs-caret {
  border-left: 1px solid #dee0e3;
  width: 35px;
  display: block;
  position: absolute;
  height: 35px;
  top: 0;
  right: 0;
}

.dropdown-toggle .bs-caret .caret {
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 6px 0 6px;
  border-color: #626262 transparent transparent transparent;
}

/** Menu **/
.dropdown-menu {
  padding: 0;
  border-radius: 0px;
  cursor: pointer;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dropdown-menu > li > a {
  font-weight: 600;
  padding: 5px 12px;
  text-decoration: none;
  font-size: 13px;
}

.dropdown-menu > li.active > a {
  background-color: #eef1f6;
  background-repeat: repeat-x;
}

.dropdown-menu > li.active > a {
  color: #333;
  text-decoration: none;
  background-color: #eef1f6;
}

.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #eef1f6;
  background-repeat: repeat-x;
  color: #000;
}

/************************************************************************************
	LISTES DEROULANTES - @ng-select/ng-select
************************************************************************************/
.ng-input > input {
  padding-left: 0;
}

/* Container */
.ng-select .ng-select-container {
  background: #fff;
  color: #333 !important;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: #dee0e3 transparent transparent #dee0e3;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.4s;
  cursor: pointer !important;
  width: 100%;
}

.ng-select.ng-select-single .ng-select-container,
.ng-select.ng-select-multiple .ng-select-container {
  height: 38px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0;
}

/* Hover */
.ng-select .ng-select-container:hover {
  background: #f4f7fa;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

/* Focus */
.ng-select.ng-select-focused .ng-select-container {
  border: 1px solid #21b8cf !important;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2) !important;
}

/* Fields in the container */
.ng-select.ng-select-focused input {
  border: none !important;
  box-shadow: none !important;
}

/* Placeholder */
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #333;
  font-weight: 400;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: auto;
  padding-bottom: 0;
  padding-left: 0;
}

/* Icon */
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #caced2 !important;
}

/************************************************************************************
	LISTES DEROULANTES - @ng-dropdown-panel
************************************************************************************/
.ng-dropdown-panel {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.ng-dropdown-panel .scroll-host {
  text-align: left;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #eef1f6;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #e0e7f0 !important;
}

/************************************************************************************
	MARKDOWN
************************************************************************************/
showdown.markdown ul {
  list-style-type: disc;
}

/***************************************************/
/*GRID*/
/***************************************************/
.grid {
  width: 100%;
  height: 100%;
}

/** GRID CONTAINER **/
.grid-container {
  display: flow-root;
  position: relative;
  padding: 0 55px 40px 55px;
}

.grid-container .grid-top-btn .filter .btn.btn-primary,
.grid-container .grid-top-btn .filter .btn.blue {
  background: #21b8cf;
  font-weight: 600;
  height: inherit;
}

.grid-container .grid-top-filter .list-filter {
  padding: 0;
}

.grid-container .grid-top-btn > div > span,
.grid-container .grid-top-filter > div > span {
  position: relative;
  color: #626262;
  font-size: 13px;
  font-weight: 400;
  line-height: 35px;
  display: none;
}

@media (max-width: 1200px) {
  .grid-container {
    padding: 0 25px 30px 25px;
  }
}
@media (max-width: 991px) {
  .grid-container {
    padding: 10px;
    padding-top: 0;
  }
}
.conf-col .dropdown-menu > li > a {
  padding: 10px 30px;
}

.conf-col .dropdown-menu > li > a:hover,
.conf-col .dropdown-menu > li > a:focus {
  background-color: #f4f7fa;
  background-image: none;
  filter: none;
}

.conf-col ol button {
  padding: 0 40px 0 12px;
  border: 0 none;
  background: #fff;
  color: #666;
  border-color: #ededed;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  border-radius: 0;
  height: 36px;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2) !important;
}

/** TABLEAU **/
.ag-theme-balham .ag-root {
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

/** ENTETES DES TABLEAUX **/
.ag-theme-balham {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.ag-theme-balham .ag-header {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  background-color: #787c81 !important;
}

.ag-theme-balham .ag-header-cell:hover {
  background: rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease 0s;
}

.ag-theme-balham .ag-header-cell:active,
.ag-theme-balham .ag-header-cell:focus,
.ag-theme-balham .ag-header-cell-moving {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0s;
}

/* Bordures des colonnes de l'entête */
.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
  border-right: none !important;
  content: none !important;
}

.ag-theme-balham .ag-header-cell-label {
  color: white;
}

.ag-theme-balham .ag-header-group-text {
  color: white;
}

.ag-theme-balham .ag-header-cell {
  border-right: 1px solid rgba(255, 255, 255, 0.15) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.15) !important;
}

/* Icone et Numéro de Tri */
.ag-theme-balham .ag-header-cell-label .ag-header-icon {
  right: 12px;
  font-size: 12px;
  opacity: 1;
}

.ag-theme-balham .ag-header-cell-label .ag-header-icon.ag-sort-order {
  padding-top: 2px;
  color: white;
}

.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
  line-height: 44px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/** LIGNES DES TABLEAUX **/
/* Couleurs alternées des lignes */
.ag-theme-balham .ag-row-odd {
  background-color: #eef1f6;
}

.ag-theme-balham .ag-row-even {
  background-color: #fff;
}

/* Couleurs des lignes au rollover */
.ag-theme-balham .ag-row.ag-row-hover:not(.grid-row-disabled),
.ag-theme-balham .ag-row.ag-row-hover-none.ag-row-hover {
  cursor: pointer;
  background: #e0e7f0 !important;
  color: #333;
}

.ag-theme-balham .ag-row.ag-row-hover-none.ag-row-hover {
  cursor: default;
}

.grid-row-disabled {
  cursor: default;
}

/* Bordures des lignes */
.ag-theme-balham .ag-row {
  border-color: #d8dbde;
}

/* Fix pour la premiere et la derniere ligne */
.ag-theme-balham .ag-row {
  border-bottom: none;
}

/** AFFICHAGE DU NOMBRE DE DOCUMENT **/
.filter .filter-count {
  display: inline-block;
  margin-left: 15px;
  color: #333;
  font-weight: 700;
}

/** TOOLBAR **/
/* Conteneur */
.grid-top-btn {
  text-align: right;
  margin-top: 15px;
  margin-bottom: 15px;
}

.grid-top-btn .filter {
  text-align: left;
}

.grid-top-btn .conf-col {
  padding-right: 15px !important;
}

/* SY Toolbar */
.careweb-toolbar {
  height: 36px;
}

.careweb-toolbar.careweb-toolbar-page {
  min-height: 60px;
}

.careweb-toolbar > * {
  display: inline-block;
  vertical-align: top;
  margin-left: 3px;
  margin-right: 3px;
}

.careweb-toolbar > *:first-child {
  margin-left: 0;
}

.careweb-toolbar > *:last-child {
  margin-right: 0;
}

.careweb-toolbar .btn,
.careweb-toolbar .btn-action {
  display: inline-block;
  height: 38px !important;
}

@media (max-width: 767px) {
  .careweb-toolbar > * {
    margin-left: 2px;
    margin-right: 2px;
  }
  .careweb-toolbar.careweb-toolbar-page {
    height: auto;
  }
  .grid-top-btn {
    text-align: left;
  }
  .grid-top-btn .filter {
    padding-bottom: 10px;
  }
}
/** GRID **/
.ui-grid-cell-contents {
  padding: 10px;
  padding-left: 0;
  line-height: 30px;
  border: 0px none;
}

.ui-grid-cell {
  border: 0px none;
  border-bottom: 1px solid #f1f1f1 !important;
  background: #fff !important;
}

.grid-container .grid-top-filter {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .grid-container .grid-top-filter {
    margin-top: 15px;
    margin-bottom: 0;
  }
}
.grid-container .grid-top-filter .list-filter {
  line-height: 40px;
}

.grid-container .grid-top-filter > div > span > span {
  font-size: 13px;
}

.grid-top-filter careweb-filter-label span .filter-label {
  display: inline-block;
  color: #fff;
  background-color: #1da4b9;
  height: 24px;
  line-height: 24px;
  padding-left: 10px;
  margin-right: 5px;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 13px;
}

/** GRID ICON **/
/* Icones d'actions dans les Tableaux */
.ui-grid-cell > span {
  display: inline-block;
  line-height: 30px;
  height: 50px;
  padding: 10px;
}

.ui-grid-cell .link:not(.not-allowed):hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.cell-bold {
  font-weight: bold;
}

.ag-theme-balham .ag-header-cell {
  border-right: 0;
}

/* .ag-theme-balham .ag-cell,
.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell {
    padding-left: 20px;
    padding-right: 20px;
} */
.ag-theme-balham .ag-cell {
  line-height: 44px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-cell i,
.ag-cell .fa {
  color: #626262;
  font-size: 1rem;
}

.ag-cell i:hover,
.ag-cell .fa:hover {
  color: black !important;
}

/* .link-icon i,
.link-icon .icon-myfont-arrowdown {
    vertical-align: middle;
    color: ;
} */
.link-icon:hover i,
.link-icon:hover .icon-myfont-arrowdown {
  color: black !important;
}

.ui-grid-cell.link-icon .ui-grid-cell-contents:hover {
  background-color: #ff0077;
}

/* BOTTOM BUTTON */
.grid-bottom {
  display: block;
  position: relative;
  float: left;
  margin-top: -25px;
}

.grid-bottom span {
  display: inline-block;
}

.grid-bottom span.btn {
  border: 0px none;
  background-color: #fff;
  color: #626262;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 10px;
  line-height: 24px;
  border-radius: 0px;
  height: inherit;
}

.grid-bottom span.btn.active {
  background: #21b8cf;
  color: #fff;
}

.grid-bottom .export-grid,
.grid-bottom .pagination-grid {
  display: inline-block;
  color: #626262;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}

.grid-bottom .export-grid {
  margin-left: 25px;
}

.pagination-grid {
  display: inline-block;
}

/* Boutons de la pagination */
.pagination-control button {
  color: #626262 !important;
  /* border-width: $sy-btn-default-border-width !important;
  border-style: $sy-btn-default-border-style !important;
  border-color: $sy-btn-default-border-color !important; */
  border: 1px solid transparent;
}

.pagination-control button:hover,
.pagination-control button:focus,
.pagination-control button:active {
  color: black !important;
  background: #f4f7fa !important;
  text-decoration: none;
}

/* TOOLTIP SUR LES STATUS */
.tooltip-grid .tooltip-inner {
  background-color: rgba(65, 65, 65, 0.95) !important;
  max-width: 350px;
  padding: 8px;
}

.tooltip.right.tooltip-grid {
  margin-left: -20px;
}

.tooltip.top.tooltip-grid {
  margin-top: 20px;
}

.tooltip.bottom.tooltip-grid {
  margin-top: -20px;
}

.tooltip.right.tooltip-grid .tooltip-arrow {
  border-right-color: rgba(65, 65, 65, 0);
}

.tooltip.top.tooltip-grid .tooltip-arrow {
  border-top-color: rgba(65, 65, 65, 0);
}

.tooltip.bottom.tooltip-grid .tooltip-arrow {
  border-bottom-color: rgba(65, 65, 65, 0);
}

.grid-row-italic-opacity {
  font-style: italic;
  opacity: 0.5;
}

.grid-row-italic {
  font-style: italic;
}

.status-comment-cell-contents {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 0px;
  height: 50px;
  border: 0px none;
}

.delete-column-button {
  box-shadow: none;
  color: black;
  background: 0 0;
  color: #626262;
}

.delete-column-button:hover {
  color: #000 !important;
}

.link-in-selected-row {
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.link-in-not-selected-rows {
  text-align: center;
  cursor: pointer;
  color: #21b8cf;
}

.ag-group-contracted, .ag-group-expanded, .ag-group-value {
  align-items: center !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.ag-icon-tree-closed, .ag-icon-tree-open {
  align-items: baseline !important;
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  box-shadow: none !important;
}

.rappro-tooltip {
  margin-bottom: 0 !important;
}

.ag-theme-balham .ag-row-selected {
  border-color: white !important;
  color: white !important;
  transition: none !important;
}

.ag-theme-balham .ag-row.ag-row-no-focus.ag-row-selected.ag-row-hover {
  border-color: white !important;
  color: white !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.ag-theme-balham, .ag-theme-balham-dark {
  --ag-selected-row-background-color: $primary !important;
}

.ag-theme-balham .ag-row-odd {
  background-color: #fff !important;
}

.ag-theme-balham .ag-row {
  border-color: #d8dbde;
}

.ag-theme-balham .ag-row {
  border-bottom: 1px solid #eaeaea;
}

.table-warning {
  background-color: rgb(255, 238, 186) !important;
}

/************************************************************************************
	LOGIN
************************************************************************************/
.loginPageBackground {
  background-image: url("../img/bg/background3.jpg");
}

.rapp-link {
  background-image: url("../img/link.png");
  background-repeat: no-repeat;
  height: 380px;
  background-size: cover;
}

.rapp-add {
  background-image: url("../img/add.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
}

.rapp-restaure {
  background-image: url("../img/restaure.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
}

.loiPageBackground {
  background-image: url("../img/bg/background3.jpg");
}

/* Lien vers les pages de récupération de mot de passe/identifiant */
.login .form-content fieldset .row.action ul {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}

.login .form-content fieldset .row.action ul li {
  color: #333;
  font-weight: 400;
  font-size: 13px;
  transition: all 0.3s ease 0s;
}

.login .form-content fieldset .row.action ul li {
  color: #20b0c6;
}

.login .back-link {
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  color: #fff;
  font-size: 12px;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}

.login .back-link:hover {
  color: #20b0c6;
}

/** FIX : moche mais pas de meilleur solution pour l'instant */
.login.recover-id .small-careweb-container {
  width: 750px;
}

.login.recover-id .identifiant.no-margin-bt {
  margin-bottom: 15px !important;
}

/************************************************************************************
	DASHBOARDS
************************************************************************************/
.dashboard .main {
  margin-bottom: 30px;
}

.dashboard-container {
  padding: 42px;
}

.dashboard-container .content {
  margin-left: 55px;
  margin-right: 24px;
}

@media (max-width: 1366px) {
  .dashboard-container [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .dashboard-container {
    padding-bottom: 0;
  }
}
/************************************************************************************
	INVOICES
************************************************************************************/
.invoices.detail .inner-container p label {
  display: inline;
}

/* DASHBOARD */
.invoices.dashboard .tiles .tile:first-child {
  margin-left: 0;
}

.invoices.dashboard .container h2 a {
  position: absolute;
  top: 0;
  right: 0;
  color: #21b8cf;
  font-size: 13px;
  line-height: 25px;
  font-weight: 600;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
  letter-spacing: 0;
}

ul li.tile a.bottom:hover {
  color: #000;
}

/* PARTIE GRID */
.invoices.dashboard .grid .row .col-md-12 {
  padding-left: 0;
}

.invoices.dashboard h2 {
  font-size: 22px;
  margin: 0 0 20px 0;
  width: 100%;
  position: relative;
}

.invoices.dashboard h2 a {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.875rem;
  line-height: 25px;
  font-weight: 600;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  letter-spacing: 0;
}

.invoices.dashboard h2 a:hover {
  color: #000;
  text-decoration: underline;
}

.invoices.dashboard .container .pushs {
  padding-left: 20px;
}

.large-tiles {
  margin: 35px 0 0;
  padding: 0;
}

/* ==========================================================================
   Construction de barre verticale de filtre
   ========================================================================== */
.filter-container {
  display: block;
  float: left;
  position: relative;
  background-color: #494c4f;
  margin-top: -6px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.filter-container-inner {
  margin-left: -310px;
  width: 310px;
  transition: margin-left 200ms ease-out 0s;
}

.filter-container-inner.show-content {
  margin-left: 0;
}

.filter-container-inner.init-hidden {
  display: none;
}

.filter-top {
  clear: both;
  overflow: hidden;
  background-color: #494c4f;
  padding: 25px;
}

.filter-top button {
  float: left;
  position: relative;
  text-align: left;
  border: 0 none;
  color: rgb(255, 255, 255);
  /* font-size: 13px; */
  font-weight: 400;
  width: auto;
  height: 40px;
  border-radius: 4px;
  background-color: #696d72;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 5px;
}

.filter-top button span {
  position: absolute;
  right: 0;
  top: 7px;
  line-height: 26px;
  height: 26px;
  width: 36px;
  text-align: center;
  border-left: 1px solid #888787;
}

.filter-top button.search {
  width: auto;
  background: #21b8cf;
  font-weight: 600;
  color: #fff;
  padding: 6px 12px;
  margin-top: 5px;
}

.filter-top button.search:hover {
  background: #23c4dc !important;
}

.filter-top button.search span,
.filter-top button.search i {
  display: none;
}

.filter-top button.reinit {
  margin-left: 0;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #dee0e3 transparent transparent #dee0e3;
  margin-top: 5px;
}

.filter-top button.reinit:hover {
  background: #f4f7fa !important;
}

.filter-top button.reinit i,
.filter-top button.reinit span {
  font-size: 16px;
  color: #626262;
}

.filter-top button.reinit:hover i,
.filter-top button.reinit:hover span {
  font-size: 16px;
  color: black;
}

.filter-top button.exit {
  color: rgba(255, 255, 255, 0.5);
  float: right;
  position: absolute;
  top: 4px;
  right: -4px;
  background: transparent;
}

.filter-top button.exit:hover {
  color: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
}

.filter-top button i,
.filter-top button span {
  position: absolute;
  right: 0;
  top: 7px;
  line-height: 26px;
  height: 26px;
  width: 36px;
  text-align: center;
  border: none;
  font-size: 18px;
}

#filter .filter-content > uib-accordion .panel {
  background-color: inherit;
  padding: 0;
  margin: 0 0 1px 0;
  border: inherit;
}

.invoices.list .filter-container .filter-content,
.invoices.list .filter-container .filter-content > ul {
  margin: 0;
  padding: 0;
}

.filter-item,
.invoices.list .filter-container .filter-content > ul > li {
  display: block;
  position: relative;
  clear: both;
  padding: 0;
  margin: 0;
  margin-bottom: 1px;
  background-color: #494c4f;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.filter-item:hover,
.panel-open .filter-item,
.invoices.list .filter-container .filter-content > ul > li.active,
.invoices.list .filter-container .filter-content > ul > li:hover {
  background-color: #21b8cf;
  color: #000;
}

.filter-item:hover,
.invoices.list .filter-container .filter-content > ul > li:hover {
  background-color: #333;
}

.panel-open .filter-item,
.invoices.list .filter-container .filter-content > ul > li.active {
  background-color: #333;
}

.panel-open .filter-item .filter-title {
  color: rgb(255, 255, 255);
}

.filter-title {
  display: block;
  height: 50px;
  padding-left: 30px;
  line-height: 50px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.filter-title .badge {
  right: 60px;
  top: 15px;
}

.filter-title:after {
  display: block;
  position: absolute;
  right: 30px;
  top: 10px;
}

.invoices.list .conf-col ol button {
  padding: 0 40px 0 12px;
  border: 0 none;
  background: #fff;
  color: #626262;
  border-color: #caced2;
  font-size: 0.75rem;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
}

/* Permet a la dropdown de passer sous le header lors du scroll */
.invoices.list #columnSelect .dropdown-menu {
  position: relative;
  margin-top: -15px;
  z-index: 10;
}

.invoices.list .conf-col .dropdown-menu > li > a {
  padding: 6px 30px;
}

.invoices.list .conf-col .dropdown-menu > li > a:hover,
.invoices.list .conf-col .dropdown-menu > li > a:focus {
  background-color: #fff;
  background-image: none;
  filter: none;
}

.invoices.list .conf-col .dropdown-menu > li > a label {
  display: block;
  position: relative;
  clear: both;
  color: #626262;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 18px;
}

.invoices.list .conf-col .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

/** Facture à valider */
.invoices.validation .line-error .error {
  position: relative;
}

.modal-dialog {
  z-index: 2200;
}

.modal-content {
  background: #f1f4f8;
  border: none !important;
  border-radius: 0.5rem;
}

.modal-header {
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  border-bottom: 1px solid transparent;
}

.modal-header .margin {
  margin-left: 15px;
}

.modal-title {
  font-size: 1.375rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.modal-body {
  padding: 0.625rem 1.25rem 0.75rem 1.25rem;
}

.modal-body .margin {
  margin-left: 15px;
  margin-right: 15px;
}

.modal-body-overflow-auto {
  max-height: 60vh !important;
  min-height: 60vh !important;
  overflow: auto;
}

.modal-footer {
  margin-top: 0;
  border-top: none;
  border-top: 1px solid transparent;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.modal-footer .btn {
  display: inline-block;
}

.modal-footer .button-bar {
  width: 100%;
  margin-top: 0;
}

.modal-footer .margin {
  margin-bottom: 30px;
  margin-right: 15px;
}

/** Largeurs des modales **/
@media (min-width: 576px) {
  .modal-xl,
  .modal-lg,
  .modal-md {
    max-width: 95%;
  }
}
@media (min-width: 992px) {
  /* Modal XL */
  .modal-xl {
    max-width: 900px;
  }
  /* Modal LG */
  .modal-lg {
    max-width: 800px;
  }
  /* Modal MD */
  .modal-md {
    max-width: 650px;
  }
}
/** Surcharge la largeur de certaines modals. */
.modal-width-fit .modal-content {
  width: fit-content;
}

/* .inmodal .modal-header {
    padding: 30px 15px;
    text-align: center;
} */
.animated.modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.inmodal .modal-icon {
  font-size: 84px;
  color: #eee;
}

.modal-show {
  display: unset !important;
}

.modal-show .modal-content {
  background: #f1f4f8;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*** RESPONSIVE *****************************/
@media (max-width: 767px) {
  .modal-title {
    font-size: 1.125rem;
  }
}
/********************************************/
/* ICON										*/
/********************************************/
.filter-content > ul > li > span:after,
.filter-title:after,
[class*=icon-myfont] {
  font-family: "myFont";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.filter-content > ul > li > span:after,
.filter-title:after,
.icon-myfont-arrowdown:before {
  content: "\e600";
}

.icon-myfont-arrowleft:before {
  content: "\e601";
}

.icon-myfont-reinit:before {
  content: "\e602";
}

.icon-myfont-arrowright:before {
  content: "\e603";
}

.icon-myfont-arrowup:before {
  content: "\e604";
}

.icon-myfont-comm:before {
  content: "\e605";
}

.icon-myfont-crayon:before {
  content: "\e606";
}

.icon-myfont-dl:before {
  content: "\e607";
}

.icon-myfont-doc3:before {
  content: "\e608";
}

.icon-myfont-doc2:before {
  content: "\e609";
}

.icon-myfont-doc1:before {
  content: "\e610";
}

.icon-myfont-info:before {
  content: "\e611";
}

.icon-myfont-cle:before {
  content: "\e612";
}

.icon-myfont-trombone:before {
  content: "\e613";
}

.icon-myfont-loupe:before {
  content: "\e614";
}

.icon-myfont-menu:before {
  content: "\e615";
}

.icon-myfont-notif:before {
  content: "\e616";
}

.icon-myfont-deco:before {
  content: "\e617";
}

.icon-myfont-compte:before {
  content: "\e618";
}

.icon-myfont-rond:before {
  content: "\e619";
}

.icon-myfont-exit:before {
  content: "\e620";
}

.header-menu,
.header-top-right > *,
.filter-item,
.filter-content ul > li {
  transition: all 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "xs" càd :
 * 	0 px < screen width < 767 px 
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/
@media screen and (max-width: 767px) {
  body.no-app {
    padding-top: 10px;
  }
  /* Responsive de la partie no-app écran de login */
  .no-app .med-careweb-container,
  .no-app .medium-careweb-container,
  .no-app .small-careweb-container,
  .no-app .large-careweb-container {
    width: 100% !important;
  }
  /* Lorsqu'on est sur une résolution de - de 768 px on prends toute la largeur de l'écran 
  * Et ce quel que soit le container (sauf le plus small-container) et on enlève du coup 
  * la marge supérieure
  */
  .app [class*=careweb-container]:not(.small-careweb-container):not(.full-careweb-container) {
    width: 100%;
    padding-top: 0;
  }
  .app [class*=careweb-container]:not(.small-careweb-container):not(.full-careweb-container) .general-terms-use.inner-container,
  .app [class*=careweb-container]:not(.small-careweb-container):not(.full-careweb-container) .billing-warrant.inner-container,
  .app [class*=careweb-container]:not(.small-careweb-container):not(.full-careweb-container) .form-content {
    border-radius: 0;
    padding-top: 30px;
  }
  /* Affichage du "Les champs marqués d’une * sont obligatoires" en noir sur fond gris */
  .app [class*=careweb-container]:not(.small-careweb-container):not(.full-careweb-container) .required-container {
    padding: 25px;
    background: #fbfcfd;
  }
  .app [class*=careweb-container]:not(.small-careweb-container):not(.full-careweb-container) .required-container .required-fields {
    color: black;
    line-height: 20px;
  }
  .app .main {
    margin-top: 70px;
  }
  /* Affichage du dashboard (home), on réduit les marges entre les tuiles 
  * et on fait disparaitre certaines tuiles (tous les indicateurs au delà du 2ème
  * disparaissent)
  */
  .dashboard.home .tiles .tile.indicator:nth-child(n+3) {
    display: none;
  }
  .invoices.dashboard .tiles .tile:nth-child(3n+1),
  .dashboard.home .tiles .tile:nth-child(2n+1) {
    margin: 0 0 10px 0 !important;
  }
  .invoices.dashboard .tiles .tile,
  .dashboard.home .tiles .tile {
    margin: 0 0 10px 10px;
  }
  /* Affichage du dashboard facture, on applique à peu près les même principes 
  * que pour le dashboard home sauf qu'on affiche 3 indicateurs, le bouton d'upload de fcature
  * devient plus fin et est en dessous des indicateurs
  * Voir ce qu'on fait pour la grille (masquage de certaines colonnes ?)
  * et pour les notifications (masquage complet ? affichage au dessus de la grille ?).
  * Les tuiles du bas prennent toute la largeur
  */
  .invoices.dashboard .tiles .tile.indicator:nth-child(n+4) {
    display: none;
  }
  .invoices.dashboard .tiles .tile.purple,
  .home.dashboard .tiles .tile.purple {
    background-position: 15px center;
    height: 70px;
    background-size: 50px;
    width: 98%;
    /*    width: 710px; */
    margin-left: 0;
  }
  .invoices.dashboard .tiles .tile.purple div a,
  .home.dashboard .tiles .tile.purple div a {
    margin-top: 0;
    line-height: 30px;
  }
  /* Pour l'instant on masque les notifications avec cet affichage */
  .invoices.dashboard .pushs {
    display: none;
  }
  /* Ajouter 15px de margin bottom pour les champs des formulaires sur 1 seule colonne */
  /*     .row .col-sm-6:not(:last-child) {
          margin-bottom: 15px;
      } */
  /* Bouton du cookie consent */
  #footer-tracking button {
    font-size: 13px;
    margin: auto;
    margin-top: 20px;
    float: left !important;
  }
  #intro-text p {
    margin-bottom: 0;
    font-weight: 600;
  }
}
/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "sm" càd : 
 *	768 px < screen width < 992 px 
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/
@media screen and (max-width: 992px) {
  /*
  * Bouton de l'écran de validation de facture utilise ça pour passer en dessous
  * quand les boutons sont les uns au dessus des autres 
  */
  .clearfix-md {
    clear: both;
  }
}
/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "md" càd :
 * 	992 px < screen width < 1200 px 
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/
/* Spécial barco (~1000px/1010px) ??? */
/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "xs" càd :
 * 	1200 px < screen width < ∞
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/
/* Il n'y a rien à faire à prioris */
/* @media screen and (max-width: 1200px) {} */
/*
 * Désactivation du bouton "clear" sur ce champ d'input sur IE SY-335
 * 
 */
input::-ms-clear {
  display: none;
}