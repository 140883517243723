
/** TABLEAU **/

.ag-theme-material .ag-root {
    border: none;
    box-shadow:  0 1px 2px rgba(0,0,0,.25);
}

/** ENTETES DES TABLEAUX **/

.ag-theme-material {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.ag-theme-material .ag-header {
    color: black !important;
    font-weight: 700 !important;
    font-size: 0.875rem !important;
    background-color: white !important;
}

.ag-theme-material .ag-header-cell:hover {
    background: rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease 0s;
}

.ag-theme-material .ag-header-cell:active,
.ag-theme-material .ag-header-cell:focus,
.ag-theme-material .ag-header-cell-moving {
    background: rgba(0, 0, 0, 0.3);
    transition:  all 0.3s ease 0s;
}

/* Bordures des colonnes de l'entête */
.ag-theme-material .ag-header-cell::after,
.ag-theme-material .ag-header-group-cell::after {
    border-right: none !important;
}

.ag-theme-material .ag-header-cell {
    border-right: 1px solid rgba(255, 255, 255, 0.15) !important;
}

/* Icone et Numéro de Tri */
.ag-theme-material .ag-header-cell-label .ag-header-icon {
    position: absolute;
    right: 12px;
    font-size: 12px;
    opacity: 1;
}

.ag-theme-material .ag-header-cell-label .ag-header-icon.ag-sort-order {
	padding-top: 2px;
}

.ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell {
    line-height: 44px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
}

/** LIGNES DES TABLEAUX **/

/* Couleurs alternées des lignes */
.ag-theme-material .ag-row-odd {
    background-color:  #fff;
}

.ag-theme-material .ag-row-even {
    background-color: #fff;
}

/* Couleurs des lignes au rollover */
.ag-theme-material .ag-row.ag-row-hover:not(.grid-row-disabled),
.ag-theme-material .ag-row.ag-row-hover-none.ag-row-hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.15) !important;
    color: #333;
}

.ag-theme-material .ag-row.ag-row-hover-none.ag-row-hover {
    cursor: default;
}

.grid-row-disabled {
    cursor: default;
}

/* Bordures des lignes */
.ag-theme-material .ag-row {
    border-color: lighten(darken(desaturate(#f1f4f8, 25%), 15%), 5%);;
}

/* Fix pour la premiere et la derniere ligne */
.ag-theme-material .ag-row {
    border-bottom: 1px solid #eaeaea;
}

.ag-floating-filter-full-body select {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 20px;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}

.ag-floating-filter-full-body select option {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #fff;
    color: rgba(0,0,0,0.87);
    padding: 8px 10px;
}

.ag-theme-material .ag-row.ag-row-last {
    border-bottom-style: none;
}


.ag-theme-material {
    
    .ag-header-group-text , .ag-header-cell-text{
        color: black ;
        font-weight: 700 ;
        font-size: 0.875rem ;
    }
    
}

.ag-theme-material .ag-root-wrapper.ag-layout-auto-height.ag-ltr{
    box-shadow:  0 1px 2px rgba(0,0,0,.25);
}

.ag-theme-material .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:focus{
    background-color: white;
}
