.align-left {
	text-align: left;
}

.pointer-cursor {
    cursor: pointer;
}

/* @TODO:  to be modified */
.red {
	color: red;
}

.errorMessage {
	color: red;
}

.blue {
	color: blue;
}

.ag-header-cell-label {
	justify-content: center !important;
	text-align: center !important;
	align-items: center !important;
}

.ag-header-merge {
	// margin-top: -50px;
	// height: 100px !important;
	// padding-top: 25px;
}

.ag-header-group-parent {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
	border-right: 1px solid rgba(255, 255, 255, 0.15) !important;
	border-left: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.ag-header-group-cell-label {
	justify-content: center !important;
}
.ag-header-group-rsp .ag-header-group-cell-label {
	justify-content: normal !important;
}

.ag-overlay-no-rows-wrapper.ag-layout-auto-height {
	padding-top: 115px !important;
	padding-bottom: 50px !important;
}

.ag-theme-balham .ag-header-row {
	border-width: 0px !important;
}

.emptyTable {
	min-height: 300px;
}

.customFilterInput {
	background: transparent !important;
	color: rgba(0, 0, 0, 0.87) !important;
	font-family: inherit !important;
	font-size: inherit !important;
	height: 40px !important;
	padding-bottom: 8px !important;
	border-width: 0 !important;
	border-bottom: 2px solid #e2e2e2 !important;
}

.customFilterInputCIS {
	background: #FFFFFFFF !important;
	border-radius: unset !important;
	box-shadow: none !important;
	text-align: center !important;
	width: 100% !important;
}

.ag-theme-arl .ag-overlay-no-rows-center,
.ag-theme-type0 .ag-overlay-no-rows-center {
	padding-bottom: 50px !important;
}

.ag-theme-editeurs .ag-overlay-no-rows-center {
	padding-bottom: 20px !important;
}

.ag-theme-organisme .ag-overlay-no-rows-center {
	padding-bottom: 20px !important;
}

.cursor-pointer,
.ag-cell-value {
	cursor: pointer;
}

.ag-cell-focus {
	border: none !important;
}

//  body :not(#modal) * {
//     -webkit-filter: blur(2px);
//     -moz-filter: blur(2px);
//     -o-filter: blur(2px);
//     -ms-filter: blur(2px);
//     filter: blur(2px);
//  }

#toast-container > div {
	opacity: 1 !important;
}

.ng-select.is-invalid {
	border-color: #f74c4c;
}

.ng-select.is-invalid:focus {
	border-color: #f74c4c !important;
	box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.oct-badge {
	font-size: 100% !important;
}

.emptyData {
	font-size: 16px;
}

.form-control-input.is-invalid {
	border: 1px solid #f74c4c;
}

/* .ag-theme-no-rows .ag-overlay-no-rows-center {
	padding-bottom: 20px !important;
} */

.red {
	color: #FF4C4C !important
}

.green {
	color: #4CA64C !important
}

.orange {
	color: #ff9317 !important;
}

.btn-disabled {
	border: 1px solid #999999;
  	background-color: #cccccc;
  	color: rgba(0,0,0,0.87);
}

.w-100 {
	width: 100%;
}

.p-relative {
	position: relative;
}

.p-absolute {
	position: absolute;
}

.border-radius-0 {
	border-radius: 0px !important;
}

.no-wrap {
	white-space: nowrap;
}

.bloc-release-notes{
	background-color: white;
	margin: 25px;
	padding: 25px;
}

.btn-warning {
	background: #ff9317;
	border: 1px solid #ff9317;
	
	&:hover {
		background: lighten(#ff9317, 3%);
		color: #fff;
		box-shadow: 1px 6px 6px 0 rgba(0,0,0,.1),1px 6px 6px 0 rgba(0,0,0,.1);
		transition: all 0.4s;
	}
}

.table-fixed-height {
    max-height: 350px!important;
}

.invalid-feedback {
	margin-top: 0px;
}

.line-breaks {
	white-space: pre-line;
}

.eye-password-show {
	position: absolute;
	right: 10px; /* Ajustez la valeur pour la position désirée */
	top: 50%; /* Place l'icône verticalement au milieu de l'input */
	transform: translateY(-50%);
	z-index: 100;
	cursor: pointer;
	pointer-events: all;
}

.modal-width {
	width: 510px;
}

.ag-row-selected::before { 
	background-color: rgba(33, 172, 193, 0.5) !important;
}

.custom-checkbox {
	box-shadow: none !important;
}

.custom-checkbox:checked {
	border-color: #21b8cf !important;
	background-color: #21b8cf !important;
	box-shadow: none;
}
.modal-title{
	width: 100%;
}
.hide{
	display: none;
}
.disabled {
	pointer-events: none;
	opacity: 0.5;
}
