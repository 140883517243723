.grid-container {
    padding: 14px 35px 40px;
}

@media (max-width: 1200px) {
    .grid-container {
        padding: 0 25px 30px 25px;
    }
}

@media (max-width: 991px) {
    .grid-container {
        padding: 10px;
        padding-top: 0;
    }
}
