/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "xs" càd :
 * 	0 px < screen width < 767 px 
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/
@media screen and (max-width: 767px) {
	body.no-app {
		padding-top: 10px;
	}
    /* Responsive de la partie no-app écran de login */
    .no-app .med-careweb-container,
    .no-app .medium-careweb-container,
	.no-app .small-careweb-container,
	.no-app .large-careweb-container	{
        width: 100% !important;
    }
    /* Lorsqu'on est sur une résolution de - de 768 px on prends toute la largeur de l'écran 
	 * Et ce quel que soit le container (sauf le plus small-container) et on enlève du coup 
	 * la marge supérieure
	 */
    .app [class*='careweb-container']:not(.small-careweb-container):not(.full-careweb-container) {
        width: 100%;
        padding-top: 0;
    }
    .app
        [class*='careweb-container']:not(.small-careweb-container):not(.full-careweb-container)
        .general-terms-use.inner-container,
    .app
        [class*='careweb-container']:not(.small-careweb-container):not(.full-careweb-container)
        .billing-warrant.inner-container,
    .app [class*='careweb-container']:not(.small-careweb-container):not(.full-careweb-container) .form-content {
        border-radius: 0;
		padding-top: 30px;
    }
    /* Affichage du "Les champs marqués d’une * sont obligatoires" en noir sur fond gris */
    .app [class*='careweb-container']:not(.small-careweb-container):not(.full-careweb-container) .required-container {
        padding: 25px;
        background: $sy-alert-default-bg;
    }
    .app
        [class*='careweb-container']:not(.small-careweb-container):not(.full-careweb-container)
        .required-container
        .required-fields {
        color: black;
        line-height: 20px;
    }
	.app .main {
		margin-top: 70px;
	}

    /* Affichage du dashboard (home), on réduit les marges entre les tuiles 
	 * et on fait disparaitre certaines tuiles (tous les indicateurs au delà du 2ème
	 * disparaissent)
	 */
    .dashboard.home .tiles .tile.indicator:nth-child(n + 3) {
        display: none;
    }
    .invoices.dashboard .tiles .tile:nth-child(3n + 1),
    .dashboard.home .tiles .tile:nth-child(2n + 1) {
        margin: 0 0 10px 0 !important;
    }
    .invoices.dashboard .tiles .tile,
    .dashboard.home .tiles .tile {
        margin: 0 0 10px 10px;
    }

    /* Affichage du dashboard facture, on applique à peu près les même principes 
	 * que pour le dashboard home sauf qu'on affiche 3 indicateurs, le bouton d'upload de fcature
	 * devient plus fin et est en dessous des indicateurs
	 * Voir ce qu'on fait pour la grille (masquage de certaines colonnes ?)
	 * et pour les notifications (masquage complet ? affichage au dessus de la grille ?).
	 * Les tuiles du bas prennent toute la largeur
	 */
    .invoices.dashboard .tiles .tile.indicator:nth-child(n + 4) {
        display: none;
    }
    .invoices.dashboard .tiles .tile.purple,
    .home.dashboard .tiles .tile.purple {
        background-position: 15px center;
        height: 70px;
        background-size: 50px;
        width: 98%;
        /*    width: 710px; */
        margin-left: 0;
    }
    .invoices.dashboard .tiles .tile.purple div a,
    .home.dashboard .tiles .tile.purple div a {
        margin-top: 0;
        line-height: 30px;
    }
    /* Pour l'instant on masque les notifications avec cet affichage */
    .invoices.dashboard .pushs {
        display: none;
    }
    /* Ajouter 15px de margin bottom pour les champs des formulaires sur 1 seule colonne */
/*     .row .col-sm-6:not(:last-child) {
        margin-bottom: 15px;
    } */
	/* Bouton du cookie consent */
	#footer-tracking button {
		font-size: 13px;
		margin: auto;
		margin-top: 20px;
		float: left!important;
	}
	#intro-text p {
		margin-bottom: 0;
		font-weight: 600;
	}
}
/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "sm" càd : 
 *	768 px < screen width < 992 px 
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/

@media screen and (max-width: 992px) {
    /*
	 * Bouton de l'écran de validation de facture utilise ça pour passer en dessous
	 * quand les boutons sont les uns au dessus des autres 
	 */
    .clearfix-md {
        clear: both;
    }
}

@media screen and (min-width: 786px) and (max-width: 992px) {
}

/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "md" càd :
 * 	992 px < screen width < 1200 px 
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/
@media screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Spécial barco (~1000px/1010px) ??? */

/**************************************
 *
 *	RESPONSIVE DESIGN pour les écrans
 *	de types "xs" càd :
 * 	1200 px < screen width < ∞
 * (on prend les mêmes résolutions que bootstrap)
 *
 **************************************/
/* Il n'y a rien à faire à prioris */
/* @media screen and (max-width: 1200px) {} */
