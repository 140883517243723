
.modal-dialog {
    z-index: 2200;
}

.modal-content {
	background: $sy-modal-bg;
    border: none !important;
	border-radius: 0.5rem;
}

.modal-header {
    padding: 1.25rem 1.25rem 0.625rem  1.25rem;
    border-bottom: 1px solid transparent;
}

.modal-header .margin {
    margin-left: 15px;
}

.modal-title {
    font-size: $sy-font-size-xxl;
    font-weight: $sy-font-weight-bold;
    color: $sy-h3-color;
    margin: 0;
}

.modal-body {
    padding: 0.625rem 1.25rem 0.75rem 1.25rem;
}

.modal-body .margin {
    margin-left: 15px;
    margin-right: 15px;
}

.modal-body-overflow-auto {
    max-height: 60vh !important;
    min-height: 60vh !important;
    overflow: auto;
}

.modal-footer {
    margin-top: 0;
    border-top: none;
    border-top: 1px solid transparent;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.modal-footer .btn {
    display: inline-block;
}

.modal-footer .button-bar {
	width: 100%;
    margin-top: 0;
}

.modal-footer .margin {
    margin-bottom: 30px;
    margin-right: 15px;
}

/** Largeurs des modales **/

@media (min-width: 576px) {
    .modal-xl,
	.modal-lg,
	.modal-md {
        max-width: 95%;
    }
}

@media (min-width: 992px) {
	/* Modal XL */
    .modal-xl {
        max-width: 900px;
    }
	/* Modal LG */
    .modal-lg {
        max-width: 800px;
    }
	/* Modal MD */
    .modal-md {
        max-width: 650px;
    }
}


/** Surcharge la largeur de certaines modals. */

.modal-width-fit .modal-content {
    width: fit-content;
}

/* .inmodal .modal-header {
    padding: 30px 15px;
    text-align: center;
} */

.animated.modal.fade .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.inmodal .modal-icon {
    font-size: 84px;
    color: #eee;
}

.modal-show{
    display: unset!important;
}

.modal-show .modal-content {
	background: $sy-modal-bg;
    box-shadow: 0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
}

/*** RESPONSIVE *****************************/

@media (max-width: 767px) {
	.modal-title {
		font-size: 1.125rem;
	}
}