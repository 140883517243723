/************************************************************************************
 *																					*
 * Font utilisée : https://www.google.com/fonts/specimen/Open+Sans					*
 * Generated by Font Squirrel (http://www.fontsquirrel.com) on November 10, 2015 	*
 *																					*
 ************************************************************************************/
@font-face {
    font-family: 'Open Sans';
    src: url('opensansbold.woff2') format('woff2'), url('opensansbold.woff') format('woff'),
        url('opensansbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensansbolditalic.woff2') format('woff2'), url('opensansbolditalic.woff') format('woff'),
        url('opensansbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensansitalic.woff2') format('woff2'), url('opensansitalic.woff') format('woff'),
        url('opensansitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensanslight.woff2') format('woff2'), url('opensanslight.woff') format('woff'),
        url('opensanslight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensanslightitalic.woff2') format('woff2'), url('opensanslightitalic.woff') format('woff'),
        url('opensanslightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensansregular.woff2') format('woff2'), url('opensansregular.woff') format('woff'),
        url('opensansregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensanssemibold.woff2') format('woff2'), url('opensanssemibold.woff') format('woff'),
        url('opensanssemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensanssemibolditalic.woff2') format('woff2'), url('opensanssemibolditalic.woff') format('woff'),
        url('opensanssemibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
