//
// Style la grille ag-grid aux couleurs de SY
//
// Pour utiliser ce style, il faut :
// 1- Modifier le style sur la grille : ag-theme-balham (c'est ce thème que l'on a surchargé)
// 2- Modifier la hauteur des lignes sur la grille : rowHeight="50" - headerHeight="50"
//

$ag-icons-path: './icons/';
$icon-color: white;
// --Ne fonctionne pas pour avoir la flêche de tri en blanc
//$grid-size: 8px;

$header-height: 50px;
$row-height: 50px;
$font-size: 12px;
$font-family: 'Open Sans', sans-serif;

$header-background-color: #7b797a;
$foreground-color: #666;
$secondary-foreground-color: white;
$secondary-font-size: 12px;
$secondary-font-weight: 600;

$grid-border-radius: 5px;

.ag-header {
    border-top-left-radius: $grid-border-radius;
    border-top-right-radius: $grid-border-radius;
}

.ag-root {
    border-radius: $grid-border-radius;
}

// @import '~ag-grid-community/src/styles/ag-grid.scss';
// @import '~ag-grid-community/src/styles/ag-theme-balham.scss';

// Applique une couleur de fond transparente pour que l'effet des bords arrondis soit élégant
// On ne surcharge pas $background-color car sinon cette couleur de fond aussi aux lignes paires
.ag-theme-balham {
    background-color: #fff;
}

.ag-theme-balham .ag-tool-panel {
    border-right: 0;
}

.ag-cell.link-icon {
    cursor: pointer;
}

.ag-header-cell-sortable .ag-header-cell-label:hover {
    cursor: pointer;
}

.ag-theme-balham .ag-icon-asc,
.ag-theme-balham .ag-icon-desc {
    opacity: 1;
    margin-right: 5px;
}

.ag-theme-balham .ag-paging-panel {
    color: $foreground-color;
}

/** Classe à utiliser lorsque l'on ne veut pas que les lignes de la grilles deviennent bleu au survol */
.ag-theme-balham .ag-row.ag-row-hover-none.ag-row-hover {
    background-color: white !important;
    cursor: auto;
    color: #666;
}

.ag-theme-balham .ag-row {
    border-top: none;
}

.ag-theme-balham .ag-row-odd {
    background-color: #f7f7f7;
}

.ag-theme-balham .ag-row-even {
    background-color: #fff;
}

.ag-theme-balham .ag-row.ag-row-hover:not(.grid-row-disabled) {
    cursor: pointer;
    background: #e7e7e7;
    color: #333;
}

.ag-row-hover .ag-cell i.fa {
    color: #777;
}

.ag-theme-balham .ag-cell.link-icon.ag-column-hover {
    background-color: transparent;
}

.ag-theme-balham .ag-header-container {
    background: transparent;
}

.ag-theme-balham .ag-header-row {
    border-bottom: 0px solid #d4d4d4;
    box-sizing: border-box;
}

.ag-theme-balham .ag-header-cell {
    border: 0px none;
    border-right: 1px solid rgba(255, 255, 255, 0);
    min-height: 50px;
    display: table-cell;
    word-wrap: break-word;
    display: block;
    outline: 0;
}

.ag-theme-balham .ag-header-cell:hover {
    background: rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease 0s;
}

.ag-theme-balham .ag-cell.ag-cell-focus {
    border: none !important;
}

.ag-cell .btn {
    box-shadow: none;
}

.ag-cell-inline-editing input[type='text'],
.ag-cell-inline-editing input[type='text']:focus {
    box-shadow: none;
}

.ag-root {
    width: 100%;
    height: auto;
    color: #333;
    font-size: 13px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    clear: both;
    border: 0px none;
}


.ag-theme-balham .ag-icon-desc:before{
    content: none !important;
}

.ag-theme-balham .ag-icon-asc:before{
    content: none !important;
}

.ag-theme-balham .ag-icon-desc {
    background-color: transparent;
    background-image: url("../ag-grid/icons/desc.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    height: 16px;
    opacity: .87;
    width: 16px;
    display: inline-block;
}

.ag-theme-balham .ag-icon-asc {
    background-color: transparent;
    background-image: url("../ag-grid/icons/asc.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    height: 16px;
    opacity: .87;
    width: 16px;
    display: inline-block;
}

.ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell, .ag-theme-material .ag-ltr .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle, .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell, .ag-theme-material .ag-rtl .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
    border: 0px
}

.ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper, .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
    min-height: 85px !important;
}