/****************************************
 *										*
 *	MyFont (icon) - Fourni par Limpide	*
 *										*
 ****************************************/
@font-face {
	font-family: "myFont";
	src:url("myFont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}