.box-content{
    & .box-indicator{
        border-right: solid 2px;
        &:last-child{
            border-right: none;
        }
    }
}

@media (max-width: 767px) {
	.box-content{
        & .box-indicator{
            border-right: none;
            border-bottom :solid 1px #eee;
            margin-bottom :1em;
            &:last-child{
                border-bottom: none;
            }
        }
    }
}



#period-form {
    padding: 0px 15px;

    .div-dropdown {
        margin-bottom: 5px;

        button {
            i:last-child {
                top: calc(25% - 13px);
                margin-left: 5px;
                font-size: 16px;
            }
        }
    }

    .box-dropdown {
        min-height: 0;
        padding: 20px 0px;
        right: 15px;
        left: 15px;
        z-index: 1;
        border-radius: 4px;

        span {
            display: block;
            padding: 8px;
        }
    }

    .period-option {   
        &:hover {
            background-color: #eef1f6;
            color: #333;
        }
    }

    .period-option-active {
        background-color:  #e0e7f0;
        font-weight: 700;
    }

    .period-option, .date-perso {
        color: rgba(0,0,0,0.87);
    }

    .date-div {
        padding: 8px 23px;

        div {
            &:first-child input {
                border-radius: 4px 0px 0px 4px;
            }

            &:last-child button {
                border-radius: 0px 4px 4px 0px;
            }

            button {
                margin-top: 1px;
                line-height: 35.5px;
            }
        }
    }
}
.spanError{
    color: red;
}